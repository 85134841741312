import {
  RequestSignUpAction,
  ReceiveSignUpDataAction,
  REQUEST_SIGNUP,
  RECEIVE_SIGNUP_DATA,
  SignUpActions
} from "./types";
import { AppState } from "..";
import { Dispatch } from "redux";
import logger from "../../logger";
import API, { SIGN_UP_URL } from "../../api";
import { ThunkDispatch } from "redux-thunk";

function requestSignUp(): RequestSignUpAction {
  return {
    type: REQUEST_SIGNUP
  };
}

function recieveSignUpData(
  error: boolean,
  payload?: object
): ReceiveSignUpDataAction {
  return {
    type: RECEIVE_SIGNUP_DATA,
    payload: payload,
    error: error
  };
}

function signUpApiCall(firstName: string, lastName: string, company: string, email: string, password: string) {
  return (dispatch: Dispatch<SignUpActions>) => {
    logger.info("Request for signup with the email: ", email);
    dispatch(requestSignUp());
    return API.post(SIGN_UP_URL, { email: email, password: password, first_name: firstName, last_name: lastName, company: company }).then(
      response => {
        dispatch(recieveSignUpData(false, undefined));
      },
      error => {
        logger.error("Error happened during sign up request:", error.response);
        var message = "Unknown eror."
        if("msg" in error.response.data){
          message = error.response.data.message;
        }
        dispatch(recieveSignUpData(true, new Error(message)));
      }
    )
  }
}

export function signUp(firstName: string, lastName: string, company: string, email: string, password: string) {
  return (
    dispatch: ThunkDispatch<AppState, null, SignUpActions>,
    getState: () => AppState
  ) => {
    return dispatch(signUpApiCall(firstName, lastName, company, email, password));
  };
}

