import conductor_type_bar from "../assets/conductor_type_bar.png";
import conductor_type_bar_selected from "../assets/conductor_type_bar_selected.png";
import conductor_type_foil from "../assets/conductor_type_foil.png";
import conductor_type_foil_selected from "../assets/conductor_type_foil_selected.png";
import conductor_type_litz from "../assets/conductor_type_litz.png";
import conductor_type_litz_selected from "../assets/conductor_type_litz_selected.png";
import core_type_ferrite from "../assets/core_type_ferrite.png";
import core_type_ferrite_selected from "../assets/core_type_ferrite_selected.png";
import core_type_goes from "../assets/core_type_goes.png";
import core_type_goes_selected from "../assets/core_type_goes_selected.png";
import core_type_nanocrystalline from "../assets/core_type_nanocrystalline.png";
import core_type_nanocrystalline_selected from "../assets/core_type_nanocrystalline_selected.png";
import mft_type_c from "../assets/mft_type_c.png";
import mft_type_c_selected from "../assets/mft_type_c_selected.png";
import mft_type_core from "../assets/mft_type_core.png";
import mft_type_core_selected from "../assets/mft_type_core_selected.png";
import mft_type_shell from "../assets/mft_type_shell.png";
import mft_type_shell_selected from "../assets/mft_type_shell_selected.png";
import {
    MftType,
    ConductorType,
    CoreType
} from "../store/designs/types";


function getImage(normal:string, selected: string, isSelected: boolean): string{
    if (isSelected){
        return selected
    } else{
        return normal
    }
}

export function getMftTypeIcon(mftType: MftType, isSelected: boolean): string {
    switch (mftType) {
        case MftType.CORE:
            return getImage(mft_type_core, mft_type_core_selected, isSelected);
        case MftType.CTYPE:
            return getImage(mft_type_c, mft_type_c_selected, isSelected);
        case MftType.SHELL:
            return getImage(mft_type_shell, mft_type_shell_selected, isSelected);
        default:
            return "";
    }
}

export function getConductorTypeIcon(conductorType: ConductorType, isSelected: boolean): string {
    switch (conductorType) {
        case ConductorType.BAR:
            return getImage(conductor_type_bar, conductor_type_bar_selected, isSelected);
        case ConductorType.FOIL:
            return getImage(conductor_type_foil, conductor_type_foil_selected, isSelected);
        case ConductorType.LITZ:
            return getImage(conductor_type_litz, conductor_type_litz_selected, isSelected);
        default:
            return "";
    }
}

export function getCoreTypeIcon(coreType: CoreType, isSelected: boolean): string {
    switch (coreType) {
        case CoreType.FERRITE:
            return getImage(core_type_ferrite, core_type_ferrite_selected, isSelected);
        case CoreType.GOES:
            return  getImage(core_type_goes, core_type_goes_selected, isSelected);
        case CoreType.NANOCRYSTALLINE:
            return getImage(core_type_nanocrystalline, core_type_nanocrystalline_selected, isSelected);
        default:
            return "";
    }

}