import React, { Component } from "react";
import "./SignUpPage.scss";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  UncontrolledAlert
} from "reactstrap";
import { connect } from "react-redux";
import { AppState } from "../store";
import { ReceiveSignUpDataAction } from "../store/signup/types";
import { signUp } from "../store/signup/actions";
import Loading from "../Loading/Loading";
import * as EmailValidator from 'email-validator';

export interface SignUpPageProps {
  pending: boolean;
  error?: Error;
  signUp: (
    firstName: string,
    lastName: string,
    company: string,
    email: string,
    password: string
  ) => Promise<void | ReceiveSignUpDataAction>;
}

interface SignUpPageState {
  firstName: string,
  lastName: string,
  company: string,
  email: string;
  password: string;
  confirmPassword: string;
  prevPending: boolean;
}

export class SignUpPage extends Component<SignUpPageProps, SignUpPageState> {
  public constructor(props: SignUpPageProps) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      company: "",
      email: "",
      password: "",
      confirmPassword: "",
      prevPending: false
    };
  }

  public componentDidUpdate(prevProps: SignUpPageProps) {
    if (this.props.pending !== prevProps.pending) {
      this.setState({ prevPending: prevProps.pending });
    }
  }

  private handleFirstNameChange = (event: any) => {
    this.setState({ firstName: event.target.value });
  };


  private handleLastNameChange = (event: any) => {
    this.setState({ lastName: event.target.value });
  };


  private handleCompanyChange = (event: any) => {
    this.setState({ company: event.target.value });
  };


  private handleEmailChange = (event: any) => {
    this.setState({ email: event.target.value });
  };

  private handlePasswordChange = (event: any) => {
    this.setState({ password: event.target.value });
  };

  private handleConfirmPasswordChange = (event: any) => {
    this.setState({ confirmPassword: event.target.value });
  };

  private handleOnSubmit = (event: any) => {
    event.preventDefault();
    this.props.signUp(
      this.state.firstName,
      this.state.lastName,
      this.state.company,
      this.state.email,
      this.state.password
    )
  };

  private isPasswordValid(): boolean {
    return this.state.password.length !== 0 &&
      this.state.password.length >= 6;
  }

  private isPasswordInValid(): boolean {
    return this.state.password.length !== 0 &&
      this.state.password.length < 6;
  }

  private isValidEmail(): boolean {
    return this.state.email.length > 0 &&
      EmailValidator.validate(this.state.email);
  }

  private isInvalidEmail(): boolean {
    return !(this.state.email.length === 0 ||
      EmailValidator.validate(this.state.email));
  }

  private isConfirmPasswordValid(): boolean {
    return this.state.confirmPassword.length > 0 &&
      this.state.confirmPassword === this.state.password;
  }

  private isConfirmPasswordInvalid(): boolean {
    return this.state.confirmPassword.length > 0 &&
      this.state.confirmPassword !== this.state.password;
  }

  private validateForm(): boolean {
    return this.state.firstName.length > 0 &&
      this.state.lastName.length > 0 &&
      this.state.company.length > 0 &&
      this.state.email.length > 0 &&
      EmailValidator.validate(this.state.email) &&
      this.state.password.length > 5 &&
      this.state.confirmPassword === this.state.password;
  };

  public render() {
    if (this.props.pending) {
      return <Loading style={{ marginTop: "300px" }}/>;
    } else if (
      !this.props.pending &&
      this.state.prevPending &&
      !this.props.error
    ) {
      // SignUp successfull
      return (
        <h2 className="SignUpWelcome">Thank you for joining trafoo. Please verify your e-mail to continue using our services.</h2>
      );
    } else
      return (
        <div>
          <h1 className="SingUpTitle">Please register to use our services</h1>
          <div className="SignUpPage">
            {this.props.error && (
              <UncontrolledAlert color="danger">
                {this.props.error.message}
              </UncontrolledAlert>
            )}
            <Form name="signupForm" onSubmit={this.handleOnSubmit}>
              <Container>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="signupFirstName">First Name</Label>
                      <Input
                        type="text"
                        name="firstName"
                        id="signupFirstName"
                        value={this.state.firstName}
                        onChange={this.handleFirstNameChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="signUpLastName">Last Name</Label>
                      <Input
                        type="text"
                        name="lastName"
                        id="signUpLastName"
                        value={this.state.lastName}
                        onChange={this.handleLastNameChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <Label for="signUpCompany">Company</Label>
                  <Input
                    type="text"
                    name="company"
                    id="signupCompany"
                    value={this.state.company}
                    onChange={this.handleCompanyChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="signupEmail">Email</Label>
                  <Input
                    type="email"
                    name="email"
                    id="signupEmail"
                    value={this.state.email}
                    onChange={this.handleEmailChange}
                    valid={this.isValidEmail()}
                    invalid={this.isInvalidEmail()}
                  />
                </FormGroup>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="signupPassword">Password</Label>
                      <Input
                        type="password"
                        name="password"
                        id="signupPassword"
                        value={this.state.password}
                        onChange={this.handlePasswordChange}
                        invalid={this.isPasswordInValid()}
                        valid={this.isPasswordValid()}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="signupConfirmPassword">Confirm Password</Label>
                      <Input
                        type="password"
                        name="confirmPassword"
                        id="signupConfirmPassword"
                        value={this.state.confirmPassword}
                        onChange={this.handleConfirmPasswordChange}
                        valid={this.isConfirmPasswordValid()}
                        invalid={this.isConfirmPasswordInvalid()}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Button
                    className="SignUpButton"
                    color="primary"
                    size="lg"
                    disabled={!this.validateForm()}>Sign Up
                </Button>
                </Row>
              </Container>
            </Form>
          </div>
        </div>
      );
  }
}

const mapStateToProps = (state: AppState) => ({
  pending: state.signUp.pending,
  error: state.signUp.error
});

export default connect(
  mapStateToProps,
  {
    signUp
  }
)(SignUpPage);
