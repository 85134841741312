import { applyMiddleware, createStore } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { combineReducers } from "redux";
import { designsReducer } from "./designs/reducers";
import { requestReducer } from "./designs/request/reducers";
import { boundsReducer } from "./designs/bounds/reducers";
import { loginReducer } from "./auth/reducers";
import { signUpReducer } from "./signup/reducers";
import { designInquiryReducer } from "./designs/inquiry/reducers";

const rootReducer = combineReducers({
  request: requestReducer,
  designBounds: boundsReducer,
  designs: designsReducer,
  login: loginReducer,
  signUp: signUpReducer,
  designInquiry: designInquiryReducer
});

export type AppState = ReturnType<typeof rootReducer>;

export default function configureStore() {
  const middlewares = [thunkMiddleware];
  const middleWareEnhancer = applyMiddleware(...middlewares);

  const store = createStore(
    rootReducer,
    composeWithDevTools(middleWareEnhancer)
  );

  return store;
}
