import React, { Component } from "react";
import "./WelcomePage.scss";
import { connect } from "react-redux";


export class WelcomePage extends Component {
  public render() {
    // Welcome page
    return (
      <h3 className="Welcome">Thank you for joining trafoo now you can go and sign in.</h3>
    );

  }
}

export default connect(
)(WelcomePage);
