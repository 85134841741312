import React from "react";
import "./Footer.scss";
import logo_inverted from "../assets/logo_inverted.png";
import mail_icon from "../assets/mail_icon.png";
import { Container, Row, Col } from "reactstrap";


class Footer extends React.Component {
  public render() {
    return (
      <Container className="footer">
        <Row className="rowFooter align-content-center">
          <Col xs={4} className="colFooter">
            <img src={logo_inverted} className="NavigationBar-logo" alt="trafoo" />
            <p className="smallSizeFooter" >optimize your design <br />
            </p>
          </Col>
          <Col xs={4} className="colFooter">
            <p>Contact <br />
              <img src={mail_icon} alt="Mail icon" className="mail_icon"></img>
              <a className="footerEmailLink stretched-link" href="mailto:info@trafoo.ch" target="_top">info@trafoo.ch</a> </p>
          </Col>
          <Col xs={4} className="colFooter">
            <p>Copyright © 2019 <br />Trafoo team </p>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Footer;
