import React, { CSSProperties } from "react";
import "./Loading.scss";
import { Spinner } from "reactstrap";

export interface LoadingProps {
  message?: string;
  style?: CSSProperties;
}

const Loading: React.SFC<LoadingProps> = (props) => {
  return (
      <div className="Loading align-content-center" style={props.style}>
        <Spinner style={{ width: "3rem", height: "3rem" }} type="grow" />{" "}
        <Spinner style={{ width: "3rem", height: "3rem" }} type="grow" />{" "}
        <Spinner style={{ width: "3rem", height: "3rem" }} type="grow" />
        {props.message && <div>{props.message}</div>}
      </div>
  );
};

export default Loading;
