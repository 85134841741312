import {
  REQUEST_DESIGNS,
  RECEIVE_DESIGNS,
  DesignsActions,
  DesignsState,
  Designs
} from "./types";

export const initialState: DesignsState = {
  isFetching: false,
  design: null
};

export function designsReducer(
  state = initialState,
  action: DesignsActions
): DesignsState {
  switch (action.type) {
    case REQUEST_DESIGNS:
      return { ...state, isFetching: true, error: undefined };
    case RECEIVE_DESIGNS:
      if (!action.error) {
        return {
          ...state,
          isFetching: false,
          design: action.payload as Designs
        };
      } else {
        return {
          ...state,
          isFetching: false,
          error: action.payload as Error
        };
      }
    default:
      return state;
  }
}