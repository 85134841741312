import {
    DesignInquiryState,
    REQUEST_DESIGN_INQUIRY,
    RECEIVE_DESIGN_INQUIRY,
    DesignInquiryActions
} from "./types";

const initialState: DesignInquiryState = {
    pending: false,
    error: undefined
}


export function designInquiryReducer(
    state = initialState,
    action: DesignInquiryActions
): DesignInquiryState {
    switch (action.type) {
        case REQUEST_DESIGN_INQUIRY:
            return { ...state, pending: true, error: undefined }
        case RECEIVE_DESIGN_INQUIRY:
            return { ...state, pending: false, error: action.payload as Error }
        default:
            return state
    }
}