import {
  LoginState,
  LoginActions,
  REQUEST_LOGIN,
  RECEIVE_LOGIN_DATA
} from "./types";

const initialState: LoginState = {
  pending: false,
  error: undefined
};

export function loginReducer(
  state = initialState,
  action: LoginActions
): LoginState {
  switch (action.type) {
    case REQUEST_LOGIN:
      return { ...state, pending: true, error: undefined };
    case RECEIVE_LOGIN_DATA:
      return { ...state, pending: false, error: action.payload as Error };
    default:
      return state;
  }
}
