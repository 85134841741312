export enum CoreMaterial {
  SI_FERRITE = "SiFerrite",
  NANOCRYSTALLINE = "Nanocrystalline"
}

export enum InsulationType {
  AIR = "Air",
  SOLID = "Solid"
}

export enum MftType {
  SHELL = "Shell",
  CORE = "Core",
  CTYPE = "CType",
  UNDEFINED = "Undefined"

}

export enum ConductorType {
  FOIL = "Foil",
  BAR = "Bar",
  LITZ = "Litz",
  UNDEFINED = "Undefined"
}

export enum CoreType {
  GOES = "Goes",
  NANOCRYSTALLINE = "Nanocrystalline",
  FERRITE = "Ferrite",
  UNDEFINED = "Undefined"
}

export interface BoundType {
  min: number;
  max: number;
}

export interface DesignsRequest {
  nominalPower: number | null;
  inputVoltage: number | null;
  outputVoltage: number | null;
  switchFrequency: number | null;
  firstWIRatingReducer: number | null;
  secondWIRatingReducer: number | null;
  coreMaterial: CoreMaterial;
  firstInsulationType: InsulationType;
  secondInsulationType: InsulationType;
  coreTempBound: BoundType | null;
  windingTempBound: BoundType | null;
  magnetizingInductBound: BoundType | null;
  leakageInductBound: BoundType | null;
  effeciencyBound: BoundType | null;
  widthBound: BoundType | null;
  lengthBound: BoundType | null;
  heightBound: BoundType | null;
  volumeBound: BoundType | null;
  weightBound: BoundType | null;
}

export interface Design {
  coreTemp: number;
  coreWeight: number;
  effeciency: number;
  height: number;
  leakageInduct: number;
  length: number;
  magnetizingInduct: number;
  totalLoss: number;
  volume: number;
  weight: number;
  width: number;
  windingTemp: number;
  windingWeight: number;
  isStandardCore: boolean;
  isStandardWire: boolean;
}

export interface Designs {
  count: number;
  Designs: Design[] | null;
}

export interface DesignsState {
  isFetching: boolean;
  design: Designs | null;
  error?: Error;
}

export const REQUEST_DESIGNS = "REQUEST_DESIGNS";
export const RECEIVE_DESIGNS = "RECEIVE_DESIGNS";

export interface RequestDesignsAction {
  type: typeof REQUEST_DESIGNS;
}

export interface ReceiveDesignsAction {
  type: typeof RECEIVE_DESIGNS;
  payload?: object;
  error?: boolean;
}

export type DesignsActions = RequestDesignsAction | ReceiveDesignsAction;
