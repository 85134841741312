import {
  SignUpState,
  SignUpActions,
  REQUEST_SIGNUP,
  RECEIVE_SIGNUP_DATA,
} from "./types";

const initialState: SignUpState = {
  pending: false,
  error: undefined
};

export function signUpReducer(
  state = initialState,
  action: SignUpActions
): SignUpState {
  switch (action.type) {
    case REQUEST_SIGNUP:
      return { ...state, pending: true, error: undefined };
    case RECEIVE_SIGNUP_DATA:
      return { ...state, pending: false, error: action.payload as Error };
    default:
      return state;
  }
}
