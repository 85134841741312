import {
  CHANGE_NOMINAL_POWER,
  CHANGE_INPUT_VOLTAGE,
  CHANGE_OUTPUT_VOLTAGE,
  CHANGE_SWITCH_FREQ,
  CHANGE_FIRST_W_I_RATING,
  CHANGE_SECOND_W_I_RATING,
  CHANGE_CORE_MATERIAL,
  CHANGE_FIRST_INSULATION_TYPE,
  CHANGE_SECOND_INSULATION_TYPE,
  CHANGE_MFT_TYPE,
  CHANGE_CONDUCTOR_TYPE,
  CHANGE_CORE_TYPE
} from "./types";
import { CoreMaterial, InsulationType, ConductorType, MftType, CoreType } from "../types";

export function changeNominalPower(nominalPower: number) {
  return {
    type: CHANGE_NOMINAL_POWER,
    payload: nominalPower
  };
}

export function changeInputVoltage(inputVoltage: number) {
  return {
    type: CHANGE_INPUT_VOLTAGE,
    payload: inputVoltage
  };
}

export function changeOutputVoltage(outputVoltage: number) {
  return {
    type: CHANGE_OUTPUT_VOLTAGE,
    payload: outputVoltage
  };
}

export function changeSwitchFreq(switchFreq: number) {
  return {
    type: CHANGE_SWITCH_FREQ,
    payload: switchFreq
  };
}

export function changeFirstWIRating(firstWIRating: number) {
  if (firstWIRating > 25) {
    firstWIRating = 25;
  }
  if (firstWIRating < 5) {
    firstWIRating = 5;
  }
  return {
    type: CHANGE_FIRST_W_I_RATING,
    payload: firstWIRating
  };
}

export function changeSecondWIRating(secondWIRating: number) {
  return {
    type: CHANGE_SECOND_W_I_RATING,
    payload: secondWIRating
  };
}

export function changeCoreMaterial(coreMaterial: CoreMaterial) {
  return {
    type: CHANGE_CORE_MATERIAL,
    payload: coreMaterial
  };
}

export function changeFirstInsulationType(firstInsulationType: InsulationType) {
  return {
    type: CHANGE_FIRST_INSULATION_TYPE,
    payload: firstInsulationType
  };
}

export function changeSecondInsulationType(
  secondInsulationType: InsulationType
) {
  return {
    type: CHANGE_SECOND_INSULATION_TYPE,
    payload: secondInsulationType
  };
}

export function changeMftType(
  mftType: MftType
) {
  return {
    type: CHANGE_MFT_TYPE,
    payload: mftType
  }
}

export function changeConductorType(
  conductorType: ConductorType
) {
  return {
    type: CHANGE_CONDUCTOR_TYPE,
    payload: conductorType
  }
}

export function changeCoreType(
  coreType: CoreType
) {
  return {
    type: CHANGE_CORE_TYPE,
    payload: coreType
  }
}