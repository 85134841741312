import { ThunkDispatch } from "redux-thunk";
import { Dispatch } from "redux";
import logger from "../../../logger";
import {
  CHANGE_CORE_TEMP_BOUND,
  CHANGE_WINDING_TEMP_BOUND,
  CHANGE_MAGNETIZING_INDUCT_BOUND,
  CHANGE_LEAKAGE_INDUCT_BOUND,
  CHANGE_EFFECIENCY_BOUND,
  CHANGE_WIDTH_BOUND,
  CHANGE_HEIGHT_BOUND,
  CHANGE_WEIGHT_BOUND,
  CHANGE_LENGTH_BOUND,
  CHANGE_VOLUME_BOUND,
  RequestDesignBoundsAction,
  REQUEST_DESIGN_BOUNDS,
  RECEIVE_DESIGN_BOUNDS,
  ReceiveDesignBoundsAction,
  DesignBoundsActions,
  DesignBounds
} from "./types";
import { BoundType, Designs, ReceiveDesignsAction } from "../types";
import { AppState } from "../..";
import API, { DESIGN_BOUNDS_URL } from "../../../api";
import { receiveData } from "../actions";
import { DesignBoundsRequest } from "../request/types";

export function changeCoreTempBound(coreTempBound: BoundType) {
  return {
    type: CHANGE_CORE_TEMP_BOUND,
    payload: coreTempBound
  };
}

export function changeWindingTempBound(windingTempBound: BoundType) {
  return {
    type: CHANGE_WINDING_TEMP_BOUND,
    payload: windingTempBound
  };
}

export function changeMagnetizingInductBound(
  magnetizingInductBound: BoundType
) {
  return {
    type: CHANGE_MAGNETIZING_INDUCT_BOUND,
    payload: magnetizingInductBound
  };
}

export function changeLeakageInductBound(leakageInductBound: BoundType) {
  return {
    type: CHANGE_LEAKAGE_INDUCT_BOUND,
    payload: leakageInductBound
  };
}

export function changeEffeciencyBound(effeciencyBound: BoundType) {
  return {
    type: CHANGE_EFFECIENCY_BOUND,
    payload: effeciencyBound
  };
}

export function changeWidthBound(widthBound: BoundType) {
  return {
    type: CHANGE_WIDTH_BOUND,
    payload: widthBound
  };
}

export function changeLengthBound(lengthBound: BoundType) {
  return {
    type: CHANGE_LENGTH_BOUND,
    payload: lengthBound
  };
}

export function changeHeightBound(heightBound: BoundType) {
  return {
    type: CHANGE_HEIGHT_BOUND,
    payload: heightBound
  };
}

export function changeVolumeBound(volumeBound: BoundType) {
  return {
    type: CHANGE_VOLUME_BOUND,
    payload: volumeBound
  };
}

export function changeWeightBound(weightBound: BoundType) {
  return {
    type: CHANGE_WEIGHT_BOUND,
    payload: weightBound
  };
}

function requestDesignBounds(): RequestDesignBoundsAction {
  return {
    type: REQUEST_DESIGN_BOUNDS
  };
}

export function recieveDesignBounds(
  error: boolean,
  payload?: object
): ReceiveDesignBoundsAction {
  return {
    type: RECEIVE_DESIGN_BOUNDS,
    payload: payload,
    error: error
  };
}

function handleErrorsOnDesignBoundsRequest(dispatch: Dispatch, error: any) {
  if (error.response) {
    logger.error(
      "The request was made and the server responded with a status code, that falls out of the range of 2xx: ",
      JSON.stringify(error.response)
    );
    var message = "Unknown eror."
    if ("msg" in error.response.data) {
      dispatch(recieveDesignBounds(true, new Error(error.response.data.msg)));
    } else {
      dispatch(recieveDesignBounds(true, new Error(message)));
    }
  } else if (error.request) {
    logger.error(
      "The request was made but no response was received: ",
      JSON.stringify(error.request)
    );
    dispatch(recieveDesignBounds(true, new Error(error.request)));
  } else {
    logger.error(
      "Something happened in setting up the request that triggered an Error: ",
      JSON.stringify(error.message)
    );
    dispatch(recieveDesignBounds(true, new Error(error)));
  }
}

function fetchDesignBounds(request: DesignBoundsRequest) {
  return (dispatch: Dispatch<DesignBoundsActions | ReceiveDesignsAction>) => {
    dispatch(requestDesignBounds());
    logger.info("Request for design bounds: ", JSON.stringify(request));
    let waitSeconds = Math.random() * 5 + 10;
    setTimeout(function () {
      return API.post(DESIGN_BOUNDS_URL, request).then(
        response => {
          logger.info(
            "Response on requesting design bounds: ",
            JSON.stringify(response)
          );

          let designs: Designs = {
            count: response.data.count,
            Designs: null
          };
          dispatch(receiveData(false, designs));

          let designBounds: DesignBounds = {
            coreTempBound: response.data.coreTempBound,
            windingTempBound: response.data.windingTempBound,
            magnetizingInductBound: response.data.magnetizingInductBound,
            leakageInductBound: response.data.leakageInductBound,
            effeciencyBound: response.data.effeciencyBound,
            widthBound: response.data.widthBound,
            lengthBound: response.data.lengthBound,
            heightBound: response.data.heightBound,
            volumeBound: response.data.volumeBound,
            weightBound: response.data.weightBound
          };
          dispatch(recieveDesignBounds(false, designBounds));
        },
        error => {
          logger.error("Error after requesting design bounds!");
          handleErrorsOnDesignBoundsRequest(dispatch, error);
        }
      );
    }, waitSeconds * 1000);
  };
}

function shouldFetchDesignBounds(state: AppState) {
  const data = state.designBounds;
  return !data || !data.isFetching;
}

export function fetchDesignBoundsIfNeeded() {
  return (
    dispatch: ThunkDispatch<AppState, null, DesignBoundsActions>,
    getState: () => AppState
  ) => {
    if (shouldFetchDesignBounds(getState())) {
      let request: DesignBoundsRequest = { ...getState().request };
      return dispatch(fetchDesignBounds(request));
    }
    return Promise.resolve();
  };
}
