export interface LoginState {
  pending: boolean;
  error?: Error;
}

export const REQUEST_LOGIN = "REQUEST_LOGIN";
export const RECEIVE_LOGIN_DATA = "RECEIVE_LOGIN_DATA";

export interface RequestLoginAction {
  type: typeof REQUEST_LOGIN;
}

export interface ReceiveLoginDataAction {
  type: typeof RECEIVE_LOGIN_DATA;
  payload?: object;
  error?: boolean;
}

export type LoginActions = RequestLoginAction | ReceiveLoginDataAction;
