import { combineReducers } from "redux";
import {
  CHANGE_CORE_TEMP_BOUND,
  CHANGE_WINDING_TEMP_BOUND,
  CHANGE_MAGNETIZING_INDUCT_BOUND,
  CHANGE_EFFECIENCY_BOUND,
  CHANGE_WIDTH_BOUND,
  CHANGE_HEIGHT_BOUND,
  CHANGE_LENGTH_BOUND,
  CHANGE_VOLUME_BOUND,
  CHANGE_WEIGHT_BOUND,
  CHANGE_LEAKAGE_INDUCT_BOUND,
  ChangeCoreTempBound,
  ChangeWindingTempBound,
  ChangeMagnetizingInductBound,
  ChangeLeakageInductBound,
  ChangeEffeciencyBound,
  ChangeWidthBound,
  ChangeLengthBound,
  ChangeHeightBound,
  ChangeVolumeBound,
  ChangeWeightBound,
  DesignBoundsState,
  DesignBoundsActions,
  RequestDesignBoundsAction,
  REQUEST_DESIGN_BOUNDS,
  ReceiveDesignBoundsAction,
  RECEIVE_DESIGN_BOUNDS,
  DesignBounds
} from "./types";
import { BoundType } from "../types";

function coreTempBoundReducer(
  state: BoundType | null = null,
  action: ChangeCoreTempBound
) {
  switch (action.type) {
    case CHANGE_CORE_TEMP_BOUND:
      return action.payload;
    default:
      return state;
  }
}

function windingTempBoundReducer(
  state: BoundType | null = null,
  action: ChangeWindingTempBound
) {
  switch (action.type) {
    case CHANGE_WINDING_TEMP_BOUND:
      return action.payload;
    default:
      return state;
  }
}

function magnetizingInductBoundReducer(
  state: BoundType | null = null,
  action: ChangeMagnetizingInductBound
) {
  switch (action.type) {
    case CHANGE_MAGNETIZING_INDUCT_BOUND:
      return action.payload;
    default:
      return state;
  }
}

function leakageInductBoundReducer(
  state: BoundType | null = null,
  action: ChangeLeakageInductBound
) {
  switch (action.type) {
    case CHANGE_LEAKAGE_INDUCT_BOUND:
      return action.payload;
    default:
      return state;
  }
}

function effeciencyBoundReducer(
  state: BoundType | null = null,
  action: ChangeEffeciencyBound
) {
  switch (action.type) {
    case CHANGE_EFFECIENCY_BOUND:
      return action.payload;
    default:
      return state;
  }
}

function widthBoundReducer(
  state: BoundType | null = null,
  action: ChangeWidthBound
) {
  switch (action.type) {
    case CHANGE_WIDTH_BOUND:
      return action.payload;
    default:
      return state;
  }
}

function lengthBoundReducer(
  state: BoundType | null = null,
  action: ChangeLengthBound
) {
  switch (action.type) {
    case CHANGE_LENGTH_BOUND:
      return action.payload;
    default:
      return state;
  }
}

function heightBoundReducer(
  state: BoundType | null = null,
  action: ChangeHeightBound
) {
  switch (action.type) {
    case CHANGE_HEIGHT_BOUND:
      return action.payload;
    default:
      return state;
  }
}

function volumeBoundReducer(
  state: BoundType | null = null,
  action: ChangeVolumeBound
) {
  switch (action.type) {
    case CHANGE_VOLUME_BOUND:
      return action.payload;
    default:
      return state;
  }
}

function weightBoundReducer(
  state: BoundType | null = null,
  action: ChangeWeightBound
) {
  switch (action.type) {
    case CHANGE_WEIGHT_BOUND:
      return action.payload;
    default:
      return state;
  }
}

const designBoundsReducer = combineReducers({
  coreTempBound: coreTempBoundReducer,
  windingTempBound: windingTempBoundReducer,
  magnetizingInductBound: magnetizingInductBoundReducer,
  leakageInductBound: leakageInductBoundReducer,
  effeciencyBound: effeciencyBoundReducer,
  widthBound: widthBoundReducer,
  lengthBound: lengthBoundReducer,
  heightBound: heightBoundReducer,
  volumeBound: volumeBoundReducer,
  weightBound: weightBoundReducer
});

function receivedBoundsReducer(
  state: DesignBoundsState,
  action: RequestDesignBoundsAction | ReceiveDesignBoundsAction
): DesignBoundsState {
  switch (action.type) {
    case REQUEST_DESIGN_BOUNDS:
      return { ...state, isFetching: true, error: undefined };
    case RECEIVE_DESIGN_BOUNDS:
      if (!action.error) {
        return {
          ...state,
          isFetching: false,
          designBounds: action.payload as DesignBounds
        };
      } else {
        return {
          ...state,
          isFetching: false,
          error: action.payload as Error
        };
      }
    default:
      return state;
  }
}

const initialState: DesignBoundsState = {
  isFetching: false,
  designBounds: {} as DesignBounds
};

export function boundsReducer(
  state = initialState,
  action: DesignBoundsActions
): DesignBoundsState {
  switch (action.type) {
    case CHANGE_CORE_TEMP_BOUND:
    case CHANGE_WINDING_TEMP_BOUND:
    case CHANGE_MAGNETIZING_INDUCT_BOUND:
    case CHANGE_EFFECIENCY_BOUND:
    case CHANGE_WIDTH_BOUND:
    case CHANGE_HEIGHT_BOUND:
    case CHANGE_LENGTH_BOUND:
    case CHANGE_VOLUME_BOUND:
    case CHANGE_WEIGHT_BOUND:
    case CHANGE_LEAKAGE_INDUCT_BOUND:
      const intermediateState = designBoundsReducer(state.designBounds, action);
      return { ...state, designBounds: intermediateState };
    case REQUEST_DESIGN_BOUNDS:
    case RECEIVE_DESIGN_BOUNDS:
      return receivedBoundsReducer(state, action);
    default:
      return state;
  }
}
