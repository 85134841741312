import React, { Component } from "react";
import "./LoginPage.scss";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  UncontrolledAlert
} from "reactstrap";
import { connect } from "react-redux";
import { loginIfNeeded } from "../store/auth/actions";
import { AppState } from "../store";
import { ReceiveLoginDataAction } from "../store/auth/types";
import Loading from "../Loading/Loading";
import { Redirect } from "react-router-dom";
import * as EmailValidator from 'email-validator';

export interface LoginPageProps {
  pending: boolean;
  error?: Error;
  loginIfNeeded: (
    email: string,
    password: string
  ) => Promise<void | ReceiveLoginDataAction>;
}

interface LoginPageState {
  email: string;
  password: string;
  prevPending: boolean;
}

export class LoginPage extends Component<LoginPageProps, LoginPageState> {
  public constructor(props: LoginPageProps) {
    super(props);
    this.state = {
      email: "",
      password: "",
      prevPending: false
    };
  }

  public componentDidUpdate(prevProps: LoginPageProps) {
    if (this.props.pending !== prevProps.pending) {
      this.setState({ prevPending: prevProps.pending });
    }
  }

  private handleEmailChange = (event: any) => {
    this.setState({ email: event.target.value });
  };

  private handlePasswordChange = (event: any) => {
    this.setState({ password: event.target.value });
  };

  private handleOnSubmit = (event: any) => {
    event.preventDefault();
    this.props.loginIfNeeded(this.state.email, this.state.password);
    this.setState({
      email: "",
      password: ""
    });
  };

  private validateForm(): boolean {
    return this.state.email.length > 0 &&
      this.state.password.length >= 6 &&
      EmailValidator.validate(this.state.email);
  }

  private isValidEmail(): boolean {
    return this.state.email.length > 0 &&
      EmailValidator.validate(this.state.email);
  }

  private isInvalidEmail(): boolean {
    return !(this.state.email.length === 0 ||
      EmailValidator.validate(this.state.email));
  }
  public render() {
    if (this.props.pending) {
      return <Loading style= {{ marginTop: "300px" }}/>;
    } else if (
      !this.props.pending &&
      this.state.prevPending &&
      !this.props.error
    ) {
      return <Redirect push to="/demo" />;
    } else
      return (
        <div className="LoginPage">
          {this.props.error && (
            <UncontrolledAlert color="danger">
              Error: <strong>{this.props.error.message}</strong>
            </UncontrolledAlert>
          )}
          <h1 className="LoginTitle" >Please login to continue</h1>
          <Form name="loginForm" onSubmit={this.handleOnSubmit}>
            <Container className="LoginContainer">
              <Row>
                <Col>
                  <FormGroup>
                    <Label for="loginEmail">Email</Label>
                    <Input
                      type="email"
                      name="email"
                      id="loginEmail"
                      value={this.state.email}
                      onChange={this.handleEmailChange}
                      valid={this.isValidEmail()}
                      invalid={this.isInvalidEmail()}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label for="loginPassword">Password</Label>
                    <Input
                      type="password"
                      name="password"
                      id="loginPassword"
                      value={this.state.password}
                      onChange={this.handlePasswordChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                  <Button
                    className="LoginButton"
                    color="success"
                    size="lg"
                    disabled={!this.validateForm()}>Login
              </Button>
              </Row>
              <Row>
                  <p className="LoginSignUpText"> Don't have an account? <a className="footerEmailLink" href="/register"> Click here</a> </p>
              </Row>
            </Container>
          </Form>
        </div>
      );
  }
}

const mapStateToProps = (state: AppState) => ({
  pending: state.login.pending,
  error: state.login.error
});

export default connect(
  mapStateToProps,
  {
    loginIfNeeded
  }
)(LoginPage);
