import { AppState } from "../..";
import { Design } from "../types";
import { DesignBoundsRequest } from "../request/types";
import { DesignInquiryActions, REQUEST_DESIGN_INQUIRY, RequestDesignInquiryAction, ReceiveDesignInquiryDataAction, RECEIVE_DESIGN_INQUIRY } from "./types";
import { Dispatch } from "redux";
import { ThunkDispatch } from "redux-thunk";
import API, { DESIGN_INQUIRY_URL } from "../../../api";
import logger from "../../../logger";



function requestDesignInquiry(): RequestDesignInquiryAction {
    return {
        type: REQUEST_DESIGN_INQUIRY
    };
}

function recieveDesignInquiryData(
    error: boolean,
    payload?: object
): ReceiveDesignInquiryDataAction {
    return {
        type: RECEIVE_DESIGN_INQUIRY,
        payload: payload,
        error: error
    };
}

function sendDesignInquryCall(design: Design, designBoundRequest: DesignBoundsRequest) {
    return (dispatch: Dispatch<DesignInquiryActions>) => {
        logger.info("Sending design inquiry: " + design);
        // Notify dispatch that request is pending
        dispatch(requestDesignInquiry())
        return API.post(DESIGN_INQUIRY_URL, {
            nominalPower: designBoundRequest.nominalPower,
            inputVoltage: designBoundRequest.inputVoltage,
            outputVoltage: designBoundRequest.outputVoltage,
            switchFrequency: designBoundRequest.switchFrequency,
            firstWIRatingReducer: designBoundRequest.firstWIRatingReducer,
            secondWIRatingReducer: designBoundRequest.secondWIRatingReducer,
            coreMaterial: designBoundRequest.coreMaterial,
            firstInsulationType: designBoundRequest.firstInsulationType,
            secondInsulationType: designBoundRequest.secondInsulationType,
            mftType: designBoundRequest.mftType,
            conductorType: designBoundRequest.conductorType,
            coreType: designBoundRequest.coreType,
            width: design.width,
            length: design.length,
            height: design.height,
            volume: design.volume,
            weight: design.weight,
            coreWeight: design.coreWeight,
            windingWeight: design.windingWeight,
            totalLoss: design.totalLoss,
            windingTemp: design.windingTemp,
            coreTemp: design.coreTemp,
            effeciency: design.effeciency,
            magnetizingInduct: design.magnetizingInduct,
            leakageInduct: design.leakageInduct,
        }).then(
            response => {
                // All good
                dispatch(recieveDesignInquiryData(false, undefined));
            },
            error => {
                logger.error("Error happened during design inquiry request:", error.response);
                var message = "Unknown eror."
                if ("msg" in error.response.data) {
                    message = error.response.data.message;
                }
                dispatch(recieveDesignInquiryData(true, new Error(message)));
            }
        )

    }

}


export function sendDesignInqury(design: Design, designBoundRequest: DesignBoundsRequest) {
    return (
        dispatch: ThunkDispatch<AppState, null, DesignInquiryActions>,
        getState: () => AppState
    ) => {
        return dispatch(sendDesignInquryCall(design, designBoundRequest));
    }
}