import * as React from 'react';
import {
    SliderItem,
    GetTrackProps
} from 'react-compound-slider';

// *******************************************************
// TRACK COMPONENT
// *******************************************************
interface ITrackProps {
    source: SliderItem;
    target: SliderItem;
    getTrackProps: GetTrackProps;
}

export const Track: React.SFC<ITrackProps> = ({
    source,
    target,
    getTrackProps
}) => (
        <div
            style={{
                position: 'absolute',
                height: '0.5rem',
                zIndex: 1,
                backgroundColor: 'lightcoral',
                borderRadius: '1rem',
                left: `${source.percent}%`,
                width: `${target.percent - source.percent}%`
            }}
            {...getTrackProps()}
        />
    );

// *******************************************************
// TICK COMPONENT
// *******************************************************
interface ITickProps {
    key: string;
    tick: SliderItem;
    count: number;
}

export const Tick: React.SFC<ITickProps> = ({ tick, count }) => (
    <div>
        <div
            style={{
                position: 'absolute',
                marginTop: '0.5rem',
                width: 1,
                height: 5,
                backgroundColor: 'rgb(200,200,200)',
                left: `${tick.percent}%`
            }}
        />
        <div
            style={{
                position: 'absolute',
                marginTop: '1rem',
                fontSize: 10,
                textAlign: 'center',
                marginLeft: `${-(100 / count) / 2}%`,
                width: `${100 / count}%`,
                left: `${tick.percent}%`
            }}
        >
            {tick.value}
        </div>
    </div>
);
