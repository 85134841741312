import React from "react";
import "./NavigationBar.scss";
import logo from "../assets/logo.png";
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavLink,
} from "reactstrap";
import { LOCAL_STORAGE_TOKEN_KEY } from "../store/auth/actions";

interface NavBarProps { }

interface NavBarState {
  isOpen: boolean;
  isLoggedIn: boolean;
}

class NavigationBar extends React.Component<NavBarProps, NavBarState> {
  public constructor(props: NavBarProps) {
    super(props);

    this.state = {
      isOpen: false,
      isLoggedIn: false
    };
  }

  public componentDidMount() {
    if (localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY)) {
      this.setState({ isLoggedIn: true });
    }
    //attach an event listener to the window object for storage event.
    window.addEventListener("storage", this.storageEventHandler);
  }

  public componentWillUnmount() {
    window.removeEventListener("storage", this.storageEventHandler);
  }

  private storageEventHandler = () => {
    if (localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY)) {
      this.setState({ isLoggedIn: true });
    } else {
      this.setState({ isLoggedIn: false });
    }
  };

  private toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  private handleOnLogoutClick = () => {
    localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
  };

  public render() {
    return (
      <Navbar light className="NavigationBar-Navbar" expand="md">
        <NavbarBrand href="/">
          <img src={logo} className="NavigationBar-logo" alt="trafoo" />
        </NavbarBrand>
        {/* TODO how to make custom style with collapse @Miksa? */}
        {/* <NavbarToggler onClick={this.toggle} /> */}
        {/* <Collapse isOpen={this.state.isOpen}> */}
        <Nav className="ml-auto">
          <NavLink className="NavigationBar-NavLink" href="/">home</NavLink>
          <NavLink className="NavigationBar-NavLink" href="/#services">services</NavLink>
          <NavLink className="NavigationBar-NavLink" href="/#about_us">about us</NavLink>
          <NavLink className="NavigationBar-NavLink" href="/demo">try it now</NavLink>
          {!this.state.isLoggedIn && <NavLink className="NavigationBar-NavLink" href="/login">login</NavLink>}
          {!this.state.isLoggedIn && <NavLink className="NavigationBar-NavLink" href="/register">register</NavLink>}
          {this.state.isLoggedIn && <NavLink className="NavigationBar-NavLink" onClick={this.handleOnLogoutClick} href="/" >sign out</NavLink>}
        </Nav>
        {/* </Collapse> */}
      </Navbar>
    );
  }
}

export default NavigationBar;
