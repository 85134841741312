import * as React from 'react';
import "./RangeSlider.scss";
import { Slider, Rail, Tracks, Ticks, GetRailProps, GetTrackProps } from 'react-compound-slider';
import { Track, Tick } from './components'; // example render components

const sliderStyle: React.CSSProperties = {
    margin: '0.5rem',
    position: 'relative',
    width: '95%'
};

export interface RangeSliderProps {
    domain: number[];
    values: number[];
    step: number;
    disabled: boolean
    onChange?: (values: ReadonlyArray<number>) => void;
}

class RangeSlider extends React.Component<RangeSliderProps> {
    public render() {
        let threshold = 4 * this.props.step
        let values = this.props.values;
        if (this.props.values[1] - this.props.values[0] <= 2 * threshold) {
            values[0] = Math.max(this.props.domain[0], this.props.values[0] - threshold);
            values[1] = Math.min(this.props.domain[1], this.props.values[1] + threshold);
        }

        return (
            <div style={{ height: '2rem', width: '100%' }}>
                <Slider
                    mode={1}
                    rootStyle={sliderStyle}
                    step={this.props.step}
                    domain={this.props.domain}
                    onChange={this.props.onChange}
                    values={values}
                >
                    <Rail>
                        {({ getRailProps }) => {
                            let props: GetRailProps = this.props.disabled ? (() => { }) as GetRailProps : getRailProps;
                            return <div className={"RangeSlider-railStyle"} {...props} />
                        }}
                    </Rail>
                    <Tracks left={false} right={false}>
                        {({ tracks, getTrackProps }) => (
                            <div className="slider-tracks">
                                {tracks.map(({ id, source, target }) => (
                                    <Track
                                        key={id}
                                        source={source}
                                        target={target}
                                        getTrackProps={this.props.disabled ? (() => { }) as GetTrackProps : getTrackProps}
                                    />
                                ))}
                            </div>
                        )}
                    </Tracks>
                    <Ticks count={10}>
                        {({ ticks }) => (
                            <div className="slider-ticks">
                                {ticks.map(tick => (
                                    <Tick key={tick.id} tick={tick} count={ticks.length} />
                                ))}
                            </div>
                        )}
                    </Ticks>
                </Slider>
            </div>
        );
    }
}

export default RangeSlider;
