import { BoundType } from "../types";

export interface DesignBounds {
  coreTempBound: BoundType | null;
  windingTempBound: BoundType | null;
  magnetizingInductBound: BoundType | null;
  leakageInductBound: BoundType | null;
  effeciencyBound: BoundType | null;
  widthBound: BoundType | null;
  lengthBound: BoundType | null;
  heightBound: BoundType | null;
  volumeBound: BoundType | null;
  weightBound: BoundType | null;
}

export interface DesignBoundsState {
  isFetching: boolean;
  designBounds: DesignBounds;
  error?: Error;
}

export const CHANGE_CORE_TEMP_BOUND = "CHANGE_CORE_TEMP_BOUND";
export const CHANGE_WINDING_TEMP_BOUND = "CHANGE_WINDING_TEMP_BOUND";
export const CHANGE_MAGNETIZING_INDUCT_BOUND =
  "CHANGE_MAGNETIZING_INDUCT_BOUND";
export const CHANGE_LEAKAGE_INDUCT_BOUND = "CHANGE_LEAKAGE_INDUCT_BOUND";
export const CHANGE_EFFECIENCY_BOUND = "CHANGE_EFFECIENCY_BOUND";
export const CHANGE_WIDTH_BOUND = "CHANGE_WIDTH_BOUND";
export const CHANGE_LENGTH_BOUND = "CHANGE_LENGTH_BOUND";
export const CHANGE_HEIGHT_BOUND = "CHANGE_HEIGHT_BOUND";
export const CHANGE_VOLUME_BOUND = "CHANGE_VOLUME_BOUND";
export const CHANGE_WEIGHT_BOUND = "CHANGE_WEIGHT_BOUND";
export const REQUEST_DESIGN_BOUNDS = "REQUEST_DESIGN_BOUNDS";
export const RECEIVE_DESIGN_BOUNDS = "RECEIVE_DESIGN_BOUNDS";

export interface ChangeCoreTempBound {
  type: typeof CHANGE_CORE_TEMP_BOUND;
  payload: BoundType;
}

export interface ChangeWindingTempBound {
  type: typeof CHANGE_WINDING_TEMP_BOUND;
  payload: BoundType;
}

export interface ChangeMagnetizingInductBound {
  type: typeof CHANGE_MAGNETIZING_INDUCT_BOUND;
  payload: BoundType;
}

export interface ChangeLeakageInductBound {
  type: typeof CHANGE_LEAKAGE_INDUCT_BOUND;
  payload: BoundType;
}

export interface ChangeEffeciencyBound {
  type: typeof CHANGE_EFFECIENCY_BOUND;
  payload: BoundType;
}

export interface ChangeWidthBound {
  type: typeof CHANGE_WIDTH_BOUND;
  payload: BoundType;
}

export interface ChangeLengthBound {
  type: typeof CHANGE_LENGTH_BOUND;
  payload: BoundType;
}

export interface ChangeHeightBound {
  type: typeof CHANGE_HEIGHT_BOUND;
  payload: BoundType;
}

export interface ChangeVolumeBound {
  type: typeof CHANGE_VOLUME_BOUND;
  payload: BoundType;
}

export interface ChangeWeightBound {
  type: typeof CHANGE_WEIGHT_BOUND;
  payload: BoundType;
}

export interface RequestDesignBoundsAction {
  type: typeof REQUEST_DESIGN_BOUNDS;
}

export interface ReceiveDesignBoundsAction {
  type: typeof RECEIVE_DESIGN_BOUNDS;
  payload?: object;
  error?: boolean;
}

export type DesignBoundsActions =
  | ChangeCoreTempBound
  | ChangeWindingTempBound
  | ChangeMagnetizingInductBound
  | ChangeLeakageInductBound
  | ChangeEffeciencyBound
  | ChangeWidthBound
  | ChangeLengthBound
  | ChangeHeightBound
  | ChangeVolumeBound
  | ChangeWeightBound
  | RequestDesignBoundsAction
  | ReceiveDesignBoundsAction;
