import React from "react";
import "./HomePage.scss";
import optimisation from "../assets/optimisation.png";
import vision from "../assets/vision.png";
import optimal from "../assets/optimal.png";
import money_time from "../assets/money_time.png";
import analyse from "../assets/analyse.png";
import landing_page from "../assets/landing_page.png";
import form_screenshot from "../assets/form_screenshot.png";
import form_specifications_screenshot from "../assets/form_specifications_screenshot.png";
import result_screenshot from "../assets/result_screenshot.png";
import { Container, Row, Col, Media, Button } from "reactstrap";

class HomePage extends React.Component {
  public render() {
    return (
      <Container className="mw-100">
        <Row className="rowHomepage100 align-content-center graybackground">
          <Col>
            <Media src={landing_page} alt="Logo" className="imageLargeLogo mt-1" />
            <div className="HomePage-CenteredTextImage">
              <h2>unlock your transformer design potential</h2>
              <Button className="HomePage-CenteredTextImage-Button" href="/demo">Try it now</Button>
            </div>
          </Col>
        </Row>
        <Row className="align-content-top align-items-center whiteBackground" id="services">
          <Col>
            <h2 className="HomePage-Heading">Trafoo</h2>
            <p className="HomePage-CenteredText">
              Transformer design tool for every electrical engineer.
              </p>
            <br />
            <p className="HomePage-CenteredText">
              Trafoo provides every electrical engineer with the ability to design a custom transformer and source the componets quickly.
              </p>
            <br />
            <p className="HomePage-CenteredText">
              Trafoo is an online transformer design platform that helps you find the optimal design in a very simple and intuitive manner. It allows you to quickly assess all the feasible design alternatives and interactively navigate to the solution that best reflects the desired performance.
            </p>
            <p className="HomePage-CenteredText centerTextMaxWidth HomePage-RedTextColor">This tool will make any engineer design transformers like a magnetics expert. </p>
            </Col>
        </Row>
        <Row className="rowHomepage100 align-content-center whiteBackground">
          <Col>
            <Media src={form_screenshot} alt="FormScreesnhot" className="align-content-center HomePage-Screenshot" />
          </Col>
          <Col className="col-md-auto align-content-center HomePage-ScreenshotTextContainer">
            <h4 className="HomePage-ScreenshotHeading">
              Choose your transformer
          </h4>
            <p>
              Select between different types of transformers.
          </p>
          </Col>
        </Row>
        <Row className="rowHomepage100 align-content-center whiteBackground">
          <Col className="align-content-center HomePage-ScreenshotTextContainer col-md-auto">
            <h4 className="HomePage-ScreenshotHeading">
              Define specifications
          </h4>
          </Col>
          <Col>
            <Media src={form_specifications_screenshot} alt="FormSpecificationsScreenshot" className="align-content-center HomePage-Screenshot" />
          </Col>
        </Row>
        <Row className="rowHomepage100 align-content-center whiteBackground">
          <Col>
            <Media src={result_screenshot} alt="ResultScreenshot" className="align-content-center HomePage-Screenshot" />
          </Col>
          <Col className="col-md-auto align-content-center HomePage-ScreenshotTextContainer">
            <h4 className="HomePage-ScreenshotHeading">
              Find your design
          </h4>
            <p>
              Explore and find your optimal design.
          </p>
          </Col>
        </Row>
        <Row className="HomePage-SmallIconsContainer" >
          <Col>
            <Media src={optimisation} alt="Logo" className="imageIcons" />
            <h3 className="HomePage-SmallTitle">Design Optimization</h3>
            <p className="HomePage-CenteredText">
              With a few simple inputs, see all the possible designs.
            </p>
          </Col>
          <Col>
            <Media src={vision} alt="Logo" className="imageIcons" />
            <h3 className="HomePage-SmallTitle">Full Feature Control</h3>
            <p className="HomePage-CenteredText">
              All the performance features are visible and can be arbitrarily limited.
            </p>
          </Col>
          <Col >
            <Media src={optimal} alt="Logo" className="imageIcons" />
            <h3 className="HomePage-SmallTitle">Optimal Performance</h3>
            <p className="HomePage-CenteredText">
              Find the optimum by interactively limiting your desired features.
            </p>
          </Col>
          <Col >
            <Media src={money_time} alt="Logo" className="imageIcons" />
            <h3 className="HomePage-SmallTitle">Time and Cost Savings</h3>
            <p className="HomePage-CenteredText">
              Limit the design set to your available or costefective components.
            </p>
          </Col>
          <Col >
            <Media src={analyse} alt="Logo" className="imageIcons" />
            <h3 className="HomePage-SmallTitle">Performance Analysis</h3>
            <p className="HomePage-CenteredText">
              See how a certain material or component compares to another.
            </p>
          </Col>
        </Row>
        <Row className="rowHomepage100 align-items-center whiteBackground" id="about_us">
          <Col>
            <h2 className="HomePage-Heading">
              About Us
            </h2>
            <p className="HomePage-CenteredText">
              Trafoo is a result of the synergistic work of its three co-founders, bringing together the advanced transformer and software design expertise. Our mission is to support any engineer in the transformer design process, resulting in cheaper and superior quality products.
            </p>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default HomePage;
