import  DesignModal  from "../DesignModal/DesignModal";
import "./DesignsTable.scss";
import { Design } from "../../store/designs/types";
import { DesignBoundsRequest } from "../../store/designs/request/types";
import { formatSI, roundMax3Decimals } from "../utils";
import { Table } from "reactstrap";
import React from "react";

export interface DesignsTableProps {
  designs: Design[],
  designBoundRequest: DesignBoundsRequest;
}

export interface DesignsTableState {
  orderModal: boolean;
  selectedDesign: Design;
}

class DesignsTable extends React.Component<DesignsTableProps, DesignsTableState> {
  public constructor(props: DesignsTableProps) {
    super(props);
    this.state = {
      orderModal: false,
      selectedDesign: this.props.designs[0],
    }
  }

  private order = (design: Design) => {
    this.setState(prevstate => ({
      selectedDesign: design,
      orderModal: true
    }));
  }

  private toggleOrderModal = () => {
    this.setState(prevState => ({
      orderModal: !prevState.orderModal
    }));
  }

  private YesOrNoSymbol(value: boolean){
    if(value){
      return <p style={{textAlign: "center"}}>&#10004;</p>
    }
    return  <p style={{textAlign: "center"}}>&#10008;</p>
  }

  public render() {
    let modalBody;
    if (this.state.orderModal){
      modalBody = <DesignModal design={this.state.selectedDesign!} designBoundRequest={this.props.designBoundRequest} action={this.toggleOrderModal}/>
    }

    return (
      <div className="DesignsTable">
        <Table hover>
          <caption>* Click on design to order it</caption>
          <thead>
            <tr>
              <th>#</th>
              <th>Standard Core</th>
              <th>Standard Wire</th>
              <th>Core Temperature</th>
              <th>Winding Temperature</th>
              <th>Magnetizing Induction</th>
              <th>Leakage Induction</th>
              <th>Efficiency</th>
              <th>Width</th>
              <th>Length</th>
              <th>Height</th>
              <th>Volume</th>
              <th>Weight</th>
            </tr>
          </thead>
          <tbody>
            {this.props.designs.map((design, index) => {
              return (
                <tr key={index + 1} style={{ cursor: 'pointer' }} onClick={e => this.order(design)}>
                  <th scope="row">{index + 1}</th>
                  <td> {this.YesOrNoSymbol(design.isStandardCore)} </td>
                  <td> {this.YesOrNoSymbol(design.isStandardWire)} </td>
                  <td>{roundMax3Decimals(design.coreTemp) + "°C"}</td>
                  <td>{roundMax3Decimals(design.windingTemp) + "°C"}</td>
                  <td>{formatSI(design.magnetizingInduct, "H").join("")}</td>
                  <td>{formatSI(design.leakageInduct, "H").join("")}</td>
                  <td>{roundMax3Decimals(design.effeciency) + "%"}</td>
                  <td>{formatSI(design.width / 1000, "m").join("")}</td>
                  <td>{formatSI(design.length / 1000, "m").join("")}</td>
                  <td>{formatSI(design.height / 1000, "m").join("")}</td>
                  <td>{formatSI(design.volume, "l").join("")}</td>
                  <td>{roundMax3Decimals(design.weight) + "kg"}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        {modalBody}
      </div>
    );
  }
}

export default DesignsTable;