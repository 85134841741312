import React from "react";
import "./ResultPage.scss";
import {
  Button,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Col,
  Row
} from "reactstrap";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { AppState } from "../store";
import DesignsTable from "./DesignsTable/DesignsTable";
import BoundsFilters from "./BoundFilters/BoundFilters";
import RequestInfo from "./RequestInfo/RequestInfo";
import { Designs, ReceiveDesignsAction } from "../store/designs/types";
import { DesignBoundsRequest } from "../store/designs/request/types";
import { DesignBounds } from "../store/designs/bounds/types";
import {
  changeCoreTempBound,
  changeWindingTempBound,
  changeMagnetizingInductBound,
  changeLeakageInductBound,
  changeEffeciencyBound,
  changeWidthBound,
  changeLengthBound,
  changeHeightBound,
  changeVolumeBound,
  changeWeightBound
} from "../store/designs/bounds/actons";
import { fetchDesignsIfNeeded } from "../store/designs/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSyncAlt, faRedoAlt } from "@fortawesome/free-solid-svg-icons";
library.add(faSyncAlt, faRedoAlt);

export interface ResultPageProps {
  request: DesignBoundsRequest;
  designs: Designs | null;
  designBounds: DesignBounds | null;
  changeCoreTempBound: typeof changeCoreTempBound;
  changeWindingTempBound: typeof changeWindingTempBound;
  changeMagnetizingInductBound: typeof changeMagnetizingInductBound;
  changeLeakageInductBound: typeof changeLeakageInductBound;
  changeEffeciencyBound: typeof changeEffeciencyBound;
  changeWidthBound: typeof changeWidthBound;
  changeLengthBound: typeof changeLengthBound;
  changeHeightBound: typeof changeHeightBound;
  changeVolumeBound: typeof changeVolumeBound;
  changeWeightBound: typeof changeWeightBound;
  error?: Error;
  fetchDesignsIfNeeded: () => Promise<void | ReceiveDesignsAction>;
}

export interface ResultPageState {
  modal: boolean;
}

export class ResultPage extends React.Component<
  ResultPageProps,
  ResultPageState
  > {

  private boundFilters: React.RefObject<BoundsFilters>;

  public constructor(props: ResultPageProps) {
    super(props);
    this.boundFilters = React.createRef();
    this.state = {
      modal: false
    };
  }

  private formatBigNumber = (num: number) => num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

  private toggleModal = () => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  };

  public render() {
    if (!this.props.designs || this.props.error) {
      return <Redirect push to="/404" />;
    } else {
      let { designs, ...boundsFilterProps } = this.props;
      return (
        <div className="ResultPage-table">
          <Container>
            <Row>
              <Col sm={8}>
                <h4>Choose your optimal parameter ranges</h4>
                <br />
                <BoundsFilters ref={this.boundFilters} {...boundsFilterProps} />
              </Col>
              <Col sm={4}>
                <RequestInfo request={this.props.request} />
                {designs && (
                  <div className="ResultPage-buttons">
                    <Button outline
                      disabled={!designs.Designs}
                      onMouseDown={this.toggleModal}
                      className="ResultPage-count-button"
                    >
                      Results{" "}
                      <Badge className="ResultPage-count-badge">
                        {this.formatBigNumber(designs.count)}
                      </Badge>
                    </Button>
                    <Button outline
                      disabled={this.boundFilters.current === null}
                      onMouseDown={(event: any) => {
                        if (this.boundFilters.current !== null) {
                          this.boundFilters.current.refreshResults()
                        }
                      }}
                      className="ResultPage-count-button"
                    >
                      <FontAwesomeIcon icon="sync-alt" />
                      {" "}Refresh
                      </Button>
                    <Button outline
                      disabled={this.boundFilters.current === null}
                      onMouseDown={(event: any) => {
                        if (this.boundFilters.current !== null) {
                          this.boundFilters.current.resetBounds()
                        }
                      }}
                      className="ResultPage-count-button"
                    >
                      <FontAwesomeIcon icon="redo-alt" />
                      {" "}Reset
                      </Button>
                  </div>
                )}
              </Col>
            </Row>
          </Container>
          {designs && designs.Designs && (
            <Modal
              className="ResultPage-modal-dialog"
              isOpen={this.state.modal}
              toggle={this.toggleModal}
            >
              <ModalHeader toggle={this.toggleModal}>Results</ModalHeader>
              <ModalBody className="ResultPage-modal-body">
                <DesignsTable designs={designs.Designs} designBoundRequest={this.props.request} />
              </ModalBody>
              <ModalFooter>
                <Button
                  className="ResultPage-modal-close"
                  onClick={this.toggleModal}
                >
                  Close
                      </Button>
              </ModalFooter>
            </Modal>
          )}
        </div>
      );
    }
  }
}

const mapStateToProps = (state: AppState) => ({
  request: state.request,
  designBounds: state.designBounds.designBounds,
  designs: state.designs.design,
  error: state.designs.error
});

export default connect(
  mapStateToProps,
  {
    changeCoreTempBound,
    changeWindingTempBound,
    changeMagnetizingInductBound,
    changeLeakageInductBound,
    changeEffeciencyBound,
    changeWidthBound,
    changeLengthBound,
    changeHeightBound,
    changeVolumeBound,
    changeWeightBound,
    fetchDesignsIfNeeded
  }
)(ResultPage);
