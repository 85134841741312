import { CoreMaterial, InsulationType, CoreType, MftType, ConductorType } from "../types";

export interface DesignBoundsRequest {
  nominalPower: number | null;
  inputVoltage: number | null;
  outputVoltage: number | null;
  switchFrequency: number | null;
  firstWIRatingReducer: number | null;
  secondWIRatingReducer: number | null;
  coreMaterial: CoreMaterial;
  firstInsulationType: InsulationType;
  secondInsulationType: InsulationType;
  mftType: MftType;
  conductorType: ConductorType;
  coreType: CoreType;
}

export const CHANGE_NOMINAL_POWER = "CHANGE_NOMINAL_POWER";
export const CHANGE_INPUT_VOLTAGE = "CHANGE_INPUT_VOLTAGE";
export const CHANGE_OUTPUT_VOLTAGE = "CHANGE_OUTPUT_VOLTAGE";
export const CHANGE_SWITCH_FREQ = "CHANGE_SWITCH_FREQ";
export const CHANGE_FIRST_W_I_RATING = "CHANGE_FIRST_W_I_RATING";
export const CHANGE_SECOND_W_I_RATING = "CHANGE_SECOND_W_I_RATING";
export const CHANGE_CORE_MATERIAL = "CHANGE_CORE_MATERIAL";
export const CHANGE_FIRST_INSULATION_TYPE = "CHANGE_FIRST_INSULATION_TYPE";
export const CHANGE_SECOND_INSULATION_TYPE = "CHANGE_SECOND_INSULATION_TYPE";
export const CHANGE_MFT_TYPE = "CHANGE_MFT_TYPE";
export const CHANGE_CONDUCTOR_TYPE = "CHANGE_CONDUCTOR_TYPE";
export const CHANGE_CORE_TYPE = "CHANGE_CORE_TYPE";



export interface ChangeNominalPowerAction {
  type: typeof CHANGE_NOMINAL_POWER;
  payload: number;
}

export interface ChangeInputVoltageAction {
  type: typeof CHANGE_INPUT_VOLTAGE;
  payload: number;
}

export interface ChangeOutputVoltageAction {
  type: typeof CHANGE_OUTPUT_VOLTAGE;
  payload: number;
}

export interface ChangeSwitchFreqAction {
  type: typeof CHANGE_SWITCH_FREQ;
  payload: number;
}

export interface ChangeFirstWIRatingAction {
  type: typeof CHANGE_FIRST_W_I_RATING;
  payload: number;
}

export interface ChangeSecondWIRatingAction {
  type: typeof CHANGE_SECOND_W_I_RATING;
  payload: number;
}

export interface ChangeCoreMaterialAction {
  type: typeof CHANGE_CORE_MATERIAL;
  payload: CoreMaterial;
}

export interface ChangeFirstInsulationTypeAction {
  type: typeof CHANGE_FIRST_INSULATION_TYPE;
  payload: InsulationType;
}

export interface ChangeSecondInsulationTypeAction {
  type: typeof CHANGE_SECOND_INSULATION_TYPE;
  payload: InsulationType;
}

export interface ChangeMftTypeAction {
  type: typeof CHANGE_MFT_TYPE;
  payload: MftType;
}

export interface ChangeConductorTypeAction {
  type: typeof CHANGE_CONDUCTOR_TYPE;
  payload: ConductorType;
}

export interface ChangeCoreTypeAction{
  type: typeof CHANGE_CORE_TYPE;
  payload: CoreType;
}