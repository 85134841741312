import "./DesignModal.scss";
import { Design } from "../../store/designs/types";
import { DesignBoundsRequest } from "../../store/designs/request/types";
import { formatSI, roundMax3Decimals } from "../utils";
import React from "react";
import { Table, Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { AppState } from "../../store";
import { ReceiveDesignInquiryDataAction } from "../../store/designs/inquiry/types";
import { connect } from "react-redux";
import { sendDesignInqury } from "../../store/designs/inquiry/actions";
import Loading from "../../Loading/Loading";

export interface DesignModalProps {
    pending: boolean;
    error?: Error;
    design: Design,
    designBoundRequest: DesignBoundsRequest;
    action: () => void;
    sendDesignInqury: (
        design: Design,
        designBoundRequest: DesignBoundsRequest
    ) => Promise<void | ReceiveDesignInquiryDataAction>;
}

export interface DesignModalState {
    prevPending: boolean;
}


class DesignModal extends React.Component<DesignModalProps, DesignModalState> {
    public constructor(props: DesignModalProps) {
        super(props);
        this.state = {
            prevPending: false,
        }
    }

    private placeOrder = () => {
        this.props.sendDesignInqury(this.props.design, this.props.designBoundRequest);
    }

    public componentDidUpdate(prevProps: DesignModalProps) {
        if (this.props.pending !== prevProps.pending) {
            this.setState({ prevPending: prevProps.pending });
        }
    }


    public render() {
        let body;
        let footer;
        if (this.props.pending) {
            body = <ModalBody>
                <Loading message="Please wait, we are processing your order." style={{ marginTop: "200px", marginBottom: "200px" }} />
            </ModalBody>;

            footer = <ModalFooter>
                <Button className="DesignsTable-modal-button"
                    onClick={this.props.action}
                >Cancel</Button>
            </ModalFooter>
        }
        else if (
            !this.props.pending &&
            this.state.prevPending &&
            !this.props.error
        ) {
            body = <ModalBody>
                <p>Thank you for your request, soon you will receive a confirmation email.</p>
            </ModalBody>;
            footer = <ModalFooter>
                <Button className="DesignsTable-modal-button"
                    onClick={this.props.action}
                >Close</Button>
            </ModalFooter>
        }
        else if (this.props.error) {
            body = <ModalBody>
                <p>Error happened during your order: {this.props.error.message}</p>
            </ModalBody>;
            footer = <ModalFooter>
                <Button className="DesignsTable-modal-button"
                    onClick={this.props.action}
                >Close</Button>
            </ModalFooter>
        }
        else {
            body = <ModalBody>
                <Table >
                    <tr>
                        <td>MFT Type:</td>
                        <td>{this.props.designBoundRequest.mftType}</td>
                    </tr>
                    <tr >
                        <td>Conductor Type:</td>
                        <td>{this.props.designBoundRequest.conductorType}</td>
                    </tr>
                    <tr >
                        <td>Core Type:</td>
                        <td>{this.props.designBoundRequest.coreType}</td>
                    </tr>
                    <tr >
                        <td>Nominal Power:</td>
                        <td>{this.props.designBoundRequest.nominalPower! / 1000}kW</td>
                    </tr>
                    <tr >
                        <td>Switch Frequency:</td>
                        <td>{this.props.designBoundRequest.switchFrequency}kHz</td>
                    </tr>
                    <tr >
                        <td>Input Voltage:</td>
                        <td>{this.props.designBoundRequest.inputVoltage}V</td>
                    </tr>
                    <tr >
                        <td>Output Voltage:</td>
                        <td>{this.props.designBoundRequest.outputVoltage}V</td>
                    </tr>
                    <tr >
                        <td>First Winding Insulation Rating:</td>
                        <td>{this.props.designBoundRequest.firstWIRatingReducer}kV</td>
                    </tr>
                    <tr >
                        <td>Second Winding Insulation Rating:</td>
                        <td>{this.props.designBoundRequest.secondWIRatingReducer}kV</td>
                    </tr>
                    <tr >
                        <td>First Insulation Type:</td>
                        <td>{this.props.designBoundRequest.firstInsulationType}</td>
                    </tr>
                    <tr >
                        <td>Second Insulation Type:</td>
                        <td>{this.props.designBoundRequest.secondInsulationType}</td>
                    </tr>
                    <tr >
                        <td>Core Material:</td>
                        <td>{this.props.designBoundRequest.coreMaterial}</td>
                    </tr>
                    <tr >
                        <td>Core Temperature:</td>
                        <td>{this.props.design.coreTemp}°C</td>
                    </tr>
                    <tr >
                        <td>Winding Temperature: </td>
                        <td>{roundMax3Decimals(this.props.design.windingTemp) + "°C"}</td>
                    </tr>
                    <tr >
                        <td> Magnetizing Induction: </td>
                        <td>{formatSI(this.props.design.magnetizingInduct, "H").join("")}</td>
                    </tr>
                    <tr >
                        <td> Leakage Induction: </td>
                        <td>{formatSI(this.props.design.leakageInduct, "H").join("")}</td>
                    </tr>
                    <tr >
                        <td> Efficiency: </td>
                        <td>{roundMax3Decimals(this.props.design.effeciency) + "%"}</td>
                    </tr>
                    <tr >
                        <td> Width: </td>
                        <td>{formatSI(this.props.design.width / 1000, "m").join("")}</td>
                    </tr>
                    <tr >
                        <td> Length:</td>
                        <td>{formatSI(this.props.design.length / 1000, "m").join("")}</td>
                    </tr>
                    <tr >
                        <td> Height:</td>
                        <td>{formatSI(this.props.design.height / 1000, "m").join("")}</td>
                    </tr>
                    <tr>
                        <td> Volume:</td>
                        <td>{formatSI(this.props.design.volume, "l").join("")}</td>
                    </tr>
                    <tr>
                        <td> Weight:</td>
                        <td>{roundMax3Decimals(this.props.design.weight)}kg</td>
                    </tr>
                </Table>
            </ModalBody>
            footer = <ModalFooter>
                <Button className="DesignsTable-modal-button"
                    onClick={this.placeOrder}
                >Place the order</Button>
                <Button className="DesignsTable-modal-button"
                    onClick={this.props.action}
                >Cancel</Button>
            </ModalFooter>
        }
        return (
            <Modal
                isOpen={true}
                toggle={this.props.action}
            >
                <ModalHeader>Order</ModalHeader>
                {body}
                {footer}
            </Modal>
        )
    }
}

const mapStateToProps = (state: AppState) => ({
    pending: state.designInquiry.pending,
    error: state.designInquiry.error
});


export default connect(
    mapStateToProps,
    {
        sendDesignInqury
    }
)(DesignModal);
