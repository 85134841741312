import React, { KeyboardEvent, FocusEvent } from "react";
import "./BoundFilters.scss";
import { Row, Col, Container, Input, InputGroup, InputGroupAddon } from "reactstrap";
import RangeSlider from "../RangeSlider/RangeSlider"
import { BoundType, ReceiveDesignsAction } from "../../store/designs/types";
import { DesignBounds } from "../../store/designs/bounds/types";
import {
  changeCoreTempBound,
  changeWindingTempBound,
  changeMagnetizingInductBound,
  changeLeakageInductBound,
  changeEffeciencyBound,
  changeWidthBound,
  changeLengthBound,
  changeHeightBound,
  changeVolumeBound,
  changeWeightBound
} from "../../store/designs/bounds/actons";
import { convertStringToNumber, convertNumberToString } from "../utils";
import { isUndefined } from "util";

export interface BoundFiltersProps {
  designBounds: DesignBounds | null;
  changeCoreTempBound: typeof changeCoreTempBound;
  changeWindingTempBound: typeof changeWindingTempBound;
  changeMagnetizingInductBound: typeof changeMagnetizingInductBound;
  changeLeakageInductBound: typeof changeLeakageInductBound;
  changeEffeciencyBound: typeof changeEffeciencyBound;
  changeWidthBound: typeof changeWidthBound;
  changeLengthBound: typeof changeLengthBound;
  changeHeightBound: typeof changeHeightBound;
  changeVolumeBound: typeof changeVolumeBound;
  changeWeightBound: typeof changeWeightBound;
  fetchDesignsIfNeeded: () => Promise<void | ReceiveDesignsAction>;
}

export interface BoundFiltersState {
  coreTempMax: string | undefined;
  windingTempMax: string | undefined;
  magnetizingInductMin: string | undefined;
  leakageInductMax: string | undefined;
  effeciencyMin: string | undefined;
  widthMax: string | undefined;
  lengthMax: string | undefined;
  heightMax: string | undefined;
  volumeMax: string | undefined;
  weightMax: string | undefined;
  coreTempBound: BoundType;
  windingTempBound: BoundType;
  magnetizingInductBound: BoundType;
  leakageInductBound: BoundType;
  effeciencyBound: BoundType;
  widthBound: BoundType;
  lengthBound: BoundType;
  heightBound: BoundType;
  volumeBound: BoundType;
  weightBound: BoundType;
}

class BoundFilters extends React.Component<
  BoundFiltersProps,
  BoundFiltersState
  > {
  public constructor(props: BoundFiltersProps) {
    super(props);
    let bounds = this.props.designBounds;
    if (
      bounds &&
      bounds.coreTempBound &&
      bounds.windingTempBound &&
      bounds.magnetizingInductBound &&
      bounds.leakageInductBound &&
      bounds.effeciencyBound &&
      bounds.widthBound &&
      bounds.lengthBound &&
      bounds.heightBound &&
      bounds.volumeBound &&
      bounds.weightBound
    ) {
      this.state = {
        coreTempMax: convertNumberToString(bounds.coreTempBound.max),
        windingTempMax: convertNumberToString(bounds.windingTempBound.max),
        magnetizingInductMin: convertNumberToString(bounds.magnetizingInductBound.min),
        leakageInductMax: convertNumberToString(bounds.leakageInductBound.max),
        effeciencyMin: convertNumberToString(bounds.effeciencyBound.min),
        widthMax: convertNumberToString(bounds.widthBound.max),
        lengthMax: convertNumberToString(bounds.lengthBound.max),
        heightMax: convertNumberToString(bounds.heightBound.max),
        volumeMax: convertNumberToString(bounds.volumeBound.max),
        weightMax: convertNumberToString(bounds.weightBound.max),
        coreTempBound: bounds.coreTempBound,
        windingTempBound: bounds.windingTempBound,
        magnetizingInductBound: bounds.magnetizingInductBound,
        leakageInductBound: bounds.leakageInductBound,
        effeciencyBound: bounds.effeciencyBound,
        widthBound: bounds.widthBound,
        lengthBound: bounds.lengthBound,
        heightBound: bounds.heightBound,
        volumeBound: bounds.volumeBound,
        weightBound: bounds.weightBound
      };
    }
  }

  public componentDidUpdate(prevProps: BoundFiltersProps) {
    if (prevProps.designBounds && this.props.designBounds) {
      if (this.props.designBounds.coreTempBound
        && this.props.designBounds.coreTempBound !== prevProps.designBounds.coreTempBound
        && !isUndefined(this.state.coreTempMax) && convertStringToNumber(this.state.coreTempMax) !== this.props.designBounds.coreTempBound.max) {
        this.setState({ coreTempMax: convertNumberToString(this.props.designBounds.coreTempBound.max) });
      }
      if (this.props.designBounds.windingTempBound
        && this.props.designBounds.windingTempBound !== prevProps.designBounds.windingTempBound
        && !isUndefined(this.state.windingTempMax) && convertStringToNumber(this.state.windingTempMax) !== this.props.designBounds.windingTempBound.max) {
        this.setState({ windingTempMax: convertNumberToString(this.props.designBounds.windingTempBound.max) });
      }
      if (this.props.designBounds.magnetizingInductBound
        && this.props.designBounds.magnetizingInductBound !== prevProps.designBounds.magnetizingInductBound
        && !isUndefined(this.state.magnetizingInductMin) && convertStringToNumber(this.state.magnetizingInductMin) !== this.props.designBounds.magnetizingInductBound.min) {
        this.setState({ magnetizingInductMin: convertNumberToString(this.props.designBounds.magnetizingInductBound.min) });
      }
      if (this.props.designBounds.leakageInductBound
        && this.props.designBounds.leakageInductBound !== prevProps.designBounds.leakageInductBound
        && !isUndefined(this.state.leakageInductMax) && convertStringToNumber(this.state.leakageInductMax) !== this.props.designBounds.leakageInductBound.max) {
        this.setState({ leakageInductMax: convertNumberToString(this.props.designBounds.leakageInductBound.max) });
      }
      if (this.props.designBounds.effeciencyBound
        && this.props.designBounds.effeciencyBound !== prevProps.designBounds.effeciencyBound
        && !isUndefined(this.state.effeciencyMin) && convertStringToNumber(this.state.effeciencyMin) !== this.props.designBounds.effeciencyBound.min) {
        this.setState({ effeciencyMin: convertNumberToString(this.props.designBounds.effeciencyBound.min) });
      }
      if (this.props.designBounds.widthBound
        && this.props.designBounds.widthBound !== prevProps.designBounds.widthBound
        && !isUndefined(this.state.widthMax) && convertStringToNumber(this.state.widthMax) !== this.props.designBounds.widthBound.max) {
        this.setState({ widthMax: convertNumberToString(this.props.designBounds.widthBound.max) });
      }
      if (this.props.designBounds.lengthBound
        && this.props.designBounds.lengthBound !== prevProps.designBounds.lengthBound
        && !isUndefined(this.state.lengthMax) && convertStringToNumber(this.state.lengthMax) !== this.props.designBounds.lengthBound.max) {
        this.setState({ lengthMax: convertNumberToString(this.props.designBounds.lengthBound.max) });
      }
      if (this.props.designBounds.heightBound
        && this.props.designBounds.heightBound !== prevProps.designBounds.heightBound
        && !isUndefined(this.state.heightMax) && convertStringToNumber(this.state.heightMax) !== this.props.designBounds.heightBound.max) {
        this.setState({ heightMax: convertNumberToString(this.props.designBounds.heightBound.max) });
      }
      if (this.props.designBounds.volumeBound
        && this.props.designBounds.volumeBound !== prevProps.designBounds.volumeBound
        && !isUndefined(this.state.volumeMax) && convertStringToNumber(this.state.volumeMax) !== this.props.designBounds.volumeBound.max) {
        this.setState({ volumeMax: convertNumberToString(this.props.designBounds.volumeBound.max) });
      }
      if (this.props.designBounds.weightBound
        && this.props.designBounds.weightBound !== prevProps.designBounds.weightBound
        && !isUndefined(this.state.weightMax) && convertStringToNumber(this.state.weightMax) !== this.props.designBounds.weightBound.max) {
        this.setState({ weightMax: convertNumberToString(this.props.designBounds.weightBound.max) });
      }
    }
  }

  public resetBounds() {
    this.props.changeCoreTempBound(this.state.coreTempBound);
    this.props.changeWindingTempBound(this.state.windingTempBound);
    this.props.changeMagnetizingInductBound(this.state.magnetizingInductBound);
    this.props.changeLeakageInductBound(this.state.leakageInductBound);
    this.props.changeEffeciencyBound(this.state.effeciencyBound);
    this.props.changeWidthBound(this.state.widthBound);
    this.props.changeLengthBound(this.state.lengthBound);
    this.props.changeHeightBound(this.state.heightBound);
    this.props.changeVolumeBound(this.state.volumeBound);
    this.props.changeWeightBound(this.state.weightBound);
    this.props.fetchDesignsIfNeeded();
  }

  public refreshResults() {
    this.props.fetchDesignsIfNeeded();
  }

  private static validateFilter = (value: number, min: number, max: number) => {
    return value >= min && value <= max;
  }

  private handleOnChangeCoreTempFilter = (value: string) => {
    this.setState({
      coreTempMax: value
    });
    let coreTempMax = convertStringToNumber(value);
    if (this.props.designBounds && this.props.designBounds.coreTempBound
      && !isNaN(coreTempMax) && BoundFilters.validateFilter(coreTempMax, this.props.designBounds.coreTempBound.min, this.state.coreTempBound.max)) {
      this.props.changeCoreTempBound({
        ...this.props.designBounds.coreTempBound,
        max: coreTempMax
      });
    }
  };

  private handleOnChangeWindingTempFilter = (value: string) => {
    this.setState({
      windingTempMax: value
    });
    let windingTempMax = convertStringToNumber(value);
    if (this.props.designBounds && this.props.designBounds.windingTempBound
      && !isNaN(windingTempMax) && BoundFilters.validateFilter(windingTempMax, this.props.designBounds.windingTempBound.min, this.state.windingTempBound.max)) {
      this.props.changeWindingTempBound({
        ...this.props.designBounds.windingTempBound,
        max: windingTempMax
      });
    }
  };

  private handleOnChangeMagnetizingInductFilter = (value: string) => {
    this.setState({
      magnetizingInductMin: value
    });
    let magnetizingInductMin = convertStringToNumber(value);
    if (this.props.designBounds && this.props.designBounds.magnetizingInductBound
      && !isNaN(magnetizingInductMin) && BoundFilters.validateFilter(magnetizingInductMin, this.state.magnetizingInductBound.min, this.props.designBounds.magnetizingInductBound.max)) {
      this.props.changeMagnetizingInductBound({
        ...this.props.designBounds.magnetizingInductBound,
        min: magnetizingInductMin
      });
    }
  };

  private handleOnChangeLeakageInductFilter = (value: string) => {
    this.setState({
      leakageInductMax: value
    });
    let leakageInductMax = convertStringToNumber(value);
    if (this.props.designBounds && this.props.designBounds.leakageInductBound
      && !isNaN(leakageInductMax) && BoundFilters.validateFilter(leakageInductMax, this.props.designBounds.leakageInductBound.min, this.state.leakageInductBound.max)) {
      this.props.changeLeakageInductBound({
        ...this.props.designBounds.leakageInductBound,
        max: leakageInductMax
      });
    }
  };

  private handleOnChangeEffeciencyFilter = (value: string) => {
    this.setState({
      effeciencyMin: value
    });
    let effeciencyMin = convertStringToNumber(value);
    if (this.props.designBounds && this.props.designBounds.effeciencyBound
      && !isNaN(effeciencyMin) && BoundFilters.validateFilter(effeciencyMin, this.state.effeciencyBound.min, this.props.designBounds.effeciencyBound.max)) {
      this.props.changeEffeciencyBound({
        ...this.props.designBounds.effeciencyBound,
        min: effeciencyMin
      });
    }
  };

  private handleOnChangeWidthFilter = (value: string) => {
    this.setState({
      widthMax: value
    });
    let widthMax = convertStringToNumber(value);
    if (this.props.designBounds && this.props.designBounds.widthBound
      && !isNaN(widthMax) && BoundFilters.validateFilter(widthMax, this.props.designBounds.widthBound.min, this.state.widthBound.max)) {
      this.props.changeWidthBound({
        ...this.props.designBounds.widthBound,
        max: widthMax
      });
    }
  };

  private handleOnChangeLengthFilter = (value: string) => {
    this.setState({
      lengthMax: value
    });
    let lengthMax = convertStringToNumber(value);
    if (this.props.designBounds && this.props.designBounds.lengthBound
      && !isNaN(lengthMax) && BoundFilters.validateFilter(lengthMax, this.props.designBounds.lengthBound.min, this.state.lengthBound.max)) {
      this.props.changeLengthBound({
        ...this.props.designBounds.lengthBound,
        max: lengthMax
      });
    }
  };

  private handleOnChangeHeightFilter = (value: string) => {
    this.setState({
      heightMax: value
    });
    let heightMax = convertStringToNumber(value);
    if (this.props.designBounds && this.props.designBounds.heightBound
      && !isNaN(heightMax) && BoundFilters.validateFilter(heightMax, this.props.designBounds.heightBound.min, this.state.heightBound.max)) {
      this.props.changeHeightBound({
        ...this.props.designBounds.heightBound,
        max: heightMax
      });
    }
  };

  private handleOnChangeVolumeFilter = (value: string) => {
    this.setState({
      volumeMax: value
    });
    let volumeMax = convertStringToNumber(value);
    if (this.props.designBounds && this.props.designBounds.volumeBound
      && !isNaN(volumeMax) && BoundFilters.validateFilter(volumeMax, this.props.designBounds.volumeBound.min, this.state.volumeBound.max)) {
      this.props.changeVolumeBound({
        ...this.props.designBounds.volumeBound,
        max: volumeMax
      });
    }
  };

  private handleOnChangeWeightFilter = (value: string) => {
    this.setState({
      weightMax: value
    });
    let weightMax = convertStringToNumber(value);
    if (this.props.designBounds && this.props.designBounds.weightBound
      && !isNaN(weightMax) && BoundFilters.validateFilter(weightMax, this.props.designBounds.weightBound.min, this.state.weightBound.max)) {
      this.props.changeWeightBound({
        ...this.props.designBounds.weightBound,
        max: weightMax
      });
    }
  };

  private handleOnKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      this.props.fetchDesignsIfNeeded();
    }
  }

  private handleOnBlur = (event: FocusEvent) => {
    this.props.fetchDesignsIfNeeded();
  }

  private static readonly STEPS_NUM = 1000;

  public render() {
    return (
      <Container>
        {this.props.designBounds && this.props.designBounds.coreTempBound &&
          <div className="BoundFilters-rows">
            <Row>
              Core Temperature
            </Row>
            <Row>
              <Col md={3}>
                <InputGroup>
                  <Input
                    disabled={true}
                    type="text"
                    value={this.props.designBounds.coreTempBound.min}
                  />
                  <InputGroupAddon addonType="append">°C</InputGroupAddon>
                </InputGroup>
              </Col>
              <Col md={6}>
                <RangeSlider
                  disabled={true}
                  domain={[this.state.coreTempBound.min, this.state.coreTempBound.max]}
                  values={[this.props.designBounds.coreTempBound.min, this.props.designBounds.coreTempBound.max]}
                  step={
                    (this.state.coreTempBound.max - this.state.coreTempBound.min) / BoundFilters.STEPS_NUM
                  }
                />
              </Col>
              <Col md={3}>
                <InputGroup>
                  <Input
                    type="text"
                    value={this.state.coreTempMax}
                    invalid={isUndefined(this.state.coreTempMax) || !BoundFilters.validateFilter(convertStringToNumber(this.state.coreTempMax), this.props.designBounds.coreTempBound.min, this.state.coreTempBound.max)}
                    onKeyDown={this.handleOnKeyDown}
                    onBlur={this.handleOnBlur}
                    onChange={event => this.handleOnChangeCoreTempFilter(event.target.value)}
                  />
                  <InputGroupAddon addonType="append">°C</InputGroupAddon>
                </InputGroup>
              </Col>
            </Row>
          </div>
        }
        {this.props.designBounds && this.props.designBounds.windingTempBound &&
          <div className="BoundFilters-rows">
            <Row>
              Winding Temperature
            </Row>
            <Row>
              <Col md={3}>
                <InputGroup>
                  <Input
                    disabled={true}
                    type="text"
                    value={this.props.designBounds.windingTempBound.min}
                  />
                  <InputGroupAddon addonType="append">°C</InputGroupAddon>
                </InputGroup>
              </Col>
              <Col md={6}>
                <RangeSlider
                  disabled={true}
                  domain={[this.state.windingTempBound.min, this.state.windingTempBound.max]}
                  values={[this.props.designBounds.windingTempBound.min, this.props.designBounds.windingTempBound.max]}
                  step={
                    (this.state.windingTempBound.max - this.state.windingTempBound.min) / BoundFilters.STEPS_NUM
                  }
                />
              </Col>
              <Col md={3}>
                <InputGroup>
                  <Input
                    type="text"
                    value={this.state.windingTempMax}
                    invalid={isUndefined(this.state.windingTempMax) || !BoundFilters.validateFilter(convertStringToNumber(this.state.windingTempMax), this.props.designBounds.windingTempBound.min, this.state.windingTempBound.max)}
                    onKeyDown={this.handleOnKeyDown}
                    onBlur={this.handleOnBlur}
                    onChange={event => this.handleOnChangeWindingTempFilter(event.target.value)}
                  />
                  <InputGroupAddon addonType="append">°C</InputGroupAddon>
                </InputGroup>
              </Col>
            </Row>
          </div>
        }
        {this.props.designBounds && this.props.designBounds.magnetizingInductBound &&
          <div className="BoundFilters-rows">
            <Row>
              Magnetizing Induction
            </Row>
            <Row>
              <Col md={3}>
                <InputGroup>
                  <Input
                    type="text"
                    value={this.state.magnetizingInductMin}
                    invalid={isUndefined(this.state.magnetizingInductMin) || !BoundFilters.validateFilter(convertStringToNumber(this.state.magnetizingInductMin), this.state.magnetizingInductBound.min, this.props.designBounds.magnetizingInductBound.max)}
                    onKeyDown={this.handleOnKeyDown}
                    onBlur={this.handleOnBlur}
                    onChange={event => this.handleOnChangeMagnetizingInductFilter(event.target.value)}
                  />
                  <InputGroupAddon addonType="append">mH</InputGroupAddon>
                </InputGroup>
              </Col>
              <Col md={6}>
                <RangeSlider
                  disabled={true}
                  domain={[this.state.magnetizingInductBound.min, this.state.magnetizingInductBound.max]}
                  values={[this.props.designBounds.magnetizingInductBound.min, this.props.designBounds.magnetizingInductBound.max]}
                  step={
                    (this.state.magnetizingInductBound.max - this.state.magnetizingInductBound.min) / BoundFilters.STEPS_NUM
                  }
                />
              </Col>
              <Col md={3}>
                <InputGroup>
                  <Input
                    disabled={true}
                    type="text"
                    value={this.props.designBounds.magnetizingInductBound.max}
                  />
                  <InputGroupAddon addonType="append">mH</InputGroupAddon>
                </InputGroup>
              </Col>
            </Row>
          </div>
        }
        {this.props.designBounds && this.props.designBounds.leakageInductBound &&
          <div className="BoundFilters-rows">
            <Row>
              Leakage Induction
            </Row>
            <Row>
              <Col md={3}>
                <InputGroup>
                  <Input
                    disabled={true}
                    type="text"
                    value={this.props.designBounds.leakageInductBound.min}
                  />
                  <InputGroupAddon addonType="append">µH</InputGroupAddon>
                </InputGroup>
              </Col>
              <Col md={6}>
                <RangeSlider
                  disabled={true}
                  domain={[this.state.leakageInductBound.min, this.state.leakageInductBound.max]}
                  values={[this.props.designBounds.leakageInductBound.min, this.props.designBounds.leakageInductBound.max]}
                  step={
                    (this.state.leakageInductBound.max - this.state.leakageInductBound.min) / BoundFilters.STEPS_NUM
                  }
                />
              </Col>
              <Col md={3}>
                <InputGroup>
                  <Input
                    type="text"
                    value={this.state.leakageInductMax}
                    invalid={isUndefined(this.state.leakageInductMax) || !BoundFilters.validateFilter(convertStringToNumber(this.state.leakageInductMax), this.props.designBounds.leakageInductBound.min, this.state.leakageInductBound.max)}
                    onKeyDown={this.handleOnKeyDown}
                    onBlur={this.handleOnBlur}
                    onChange={event => this.handleOnChangeLeakageInductFilter(event.target.value)}
                  />
                  <InputGroupAddon addonType="append">µH</InputGroupAddon>
                </InputGroup>
              </Col>
            </Row>
          </div>
        }
        {this.props.designBounds && this.props.designBounds.effeciencyBound &&
          <div className="BoundFilters-rows">
            <Row>
              Efficiency
            </Row>
            <Row>
              <Col md={3}>
                <InputGroup>
                  <Input
                    type="text"
                    value={this.state.effeciencyMin}
                    invalid={isUndefined(this.state.effeciencyMin) || !BoundFilters.validateFilter(convertStringToNumber(this.state.effeciencyMin), this.state.effeciencyBound.min, this.props.designBounds.effeciencyBound.max)}
                    onKeyDown={this.handleOnKeyDown}
                    onBlur={this.handleOnBlur}
                    onChange={event => this.handleOnChangeEffeciencyFilter(event.target.value)}
                  />
                  <InputGroupAddon addonType="append">%</InputGroupAddon>
                </InputGroup>
              </Col>
              <Col md={6}>
                <RangeSlider
                  disabled={true}
                  domain={[this.state.effeciencyBound.min, this.state.effeciencyBound.max]}
                  values={[this.props.designBounds.effeciencyBound.min, this.props.designBounds.effeciencyBound.max]}
                  step={
                    (this.state.effeciencyBound.max - this.state.effeciencyBound.min) / BoundFilters.STEPS_NUM
                  }
                />
              </Col>
              <Col md={3}>
                <InputGroup>
                  <Input
                    disabled={true}
                    type="text"
                    value={this.props.designBounds.effeciencyBound.max}
                  />
                  <InputGroupAddon addonType="append">%</InputGroupAddon>
                </InputGroup>
              </Col>
            </Row>
          </div>
        }
        {this.props.designBounds && this.props.designBounds.widthBound &&
          <div className="BoundFilters-rows">
            <Row>
              Width
            </Row>
            <Row>
              <Col md={3}>
                <InputGroup>
                  <Input
                    disabled={true}
                    type="text"
                    value={this.props.designBounds.widthBound.min}
                  />
                  <InputGroupAddon addonType="append">m</InputGroupAddon>
                </InputGroup>
              </Col>
              <Col md={6}>
                <RangeSlider
                  disabled={true}
                  domain={[this.state.widthBound.min, this.state.widthBound.max]}
                  values={[this.props.designBounds.widthBound.min, this.props.designBounds.widthBound.max]}
                  step={
                    (this.state.widthBound.max - this.state.widthBound.min) / BoundFilters.STEPS_NUM
                  }
                />
              </Col>
              <Col md={3}>
                <InputGroup>
                  <Input
                    type="text"
                    value={this.state.widthMax}
                    invalid={isUndefined(this.state.widthMax) || !BoundFilters.validateFilter(convertStringToNumber(this.state.widthMax), this.props.designBounds.widthBound.min, this.state.widthBound.max)}
                    onKeyDown={this.handleOnKeyDown}
                    onBlur={this.handleOnBlur}
                    onChange={event => this.handleOnChangeWidthFilter(event.target.value)}
                  />
                  <InputGroupAddon addonType="append">m</InputGroupAddon>
                </InputGroup>
              </Col>
            </Row>
          </div>
        }
        {this.props.designBounds && this.props.designBounds.lengthBound &&
          <div className="BoundFilters-rows">
            <Row>
              Length
            </Row>
            <Row>
              <Col md={3}>
                <InputGroup>
                  <Input
                    disabled={true}
                    type="text"
                    value={this.props.designBounds.lengthBound.min}
                  />
                  <InputGroupAddon addonType="append">m</InputGroupAddon>
                </InputGroup>
              </Col>
              <Col md={6}>
                <RangeSlider
                  disabled={true}
                  domain={[this.state.lengthBound.min, this.state.lengthBound.max]}
                  values={[this.props.designBounds.lengthBound.min, this.props.designBounds.lengthBound.max]}
                  step={
                    (this.state.lengthBound.max - this.state.lengthBound.min) / BoundFilters.STEPS_NUM
                  }
                />
              </Col>
              <Col md={3}>
                <InputGroup>
                  <Input
                    type="text"
                    value={this.state.lengthMax}
                    invalid={isUndefined(this.state.lengthMax) || !BoundFilters.validateFilter(convertStringToNumber(this.state.lengthMax), this.props.designBounds.lengthBound.min, this.state.lengthBound.max)}
                    onKeyDown={this.handleOnKeyDown}
                    onBlur={this.handleOnBlur}
                    onChange={event => this.handleOnChangeLengthFilter(event.target.value)}
                  />
                  <InputGroupAddon addonType="append">m</InputGroupAddon>
                </InputGroup>
              </Col>
            </Row>
          </div>
        }
        {this.props.designBounds && this.props.designBounds.heightBound &&
          <div className="BoundFilters-rows">
            <Row>
              Height
            </Row>
            <Row>
              <Col md={3}>
                <InputGroup>
                  <Input
                    disabled={true}
                    type="text"
                    value={this.props.designBounds.heightBound.min}
                  />
                  <InputGroupAddon addonType="append">m</InputGroupAddon>
                </InputGroup>
              </Col>
              <Col md={6}>
                <RangeSlider
                  disabled={true}
                  domain={[this.state.heightBound.min, this.state.heightBound.max]}
                  values={[this.props.designBounds.heightBound.min, this.props.designBounds.heightBound.max]}
                  step={
                    (this.state.heightBound.max - this.state.heightBound.min) / BoundFilters.STEPS_NUM
                  }
                />
              </Col>
              <Col md={3}>
                <InputGroup>
                  <Input
                    type="text"
                    value={this.state.heightMax}
                    invalid={isUndefined(this.state.heightMax) || !BoundFilters.validateFilter(convertStringToNumber(this.state.heightMax), this.props.designBounds.heightBound.min, this.state.heightBound.max)}
                    onKeyDown={this.handleOnKeyDown}
                    onBlur={this.handleOnBlur}
                    onChange={event => this.handleOnChangeHeightFilter(event.target.value)}
                  />
                  <InputGroupAddon addonType="append">m</InputGroupAddon>
                </InputGroup>
              </Col>
            </Row>
          </div>
        }
        {this.props.designBounds && this.props.designBounds.volumeBound &&
          <div className="BoundFilters-rows">
            <Row>
              Volume
            </Row>
            <Row>
              <Col md={3}>
                <InputGroup>
                  <Input
                    disabled={true}
                    type="text"
                    value={this.props.designBounds.volumeBound.min}
                  />
                  <InputGroupAddon addonType="append">l</InputGroupAddon>
                </InputGroup>
              </Col>
              <Col md={6}>
                <RangeSlider
                  disabled={true}
                  domain={[this.state.volumeBound.min, this.state.volumeBound.max]}
                  values={[this.props.designBounds.volumeBound.min, this.props.designBounds.volumeBound.max]}
                  step={
                    (this.state.volumeBound.max - this.state.volumeBound.min) / BoundFilters.STEPS_NUM
                  }
                />
              </Col>
              <Col md={3}>
                <InputGroup>
                  <Input
                    type="text"
                    value={this.state.volumeMax}
                    invalid={isUndefined(this.state.volumeMax) || !BoundFilters.validateFilter(convertStringToNumber(this.state.volumeMax), this.props.designBounds.volumeBound.min, this.state.volumeBound.max)}
                    onKeyDown={this.handleOnKeyDown}
                    onBlur={this.handleOnBlur}
                    onChange={event => this.handleOnChangeVolumeFilter(event.target.value)}
                  />
                  <InputGroupAddon addonType="append">l</InputGroupAddon>
                </InputGroup>
              </Col>
            </Row>
          </div>
        }
        {this.props.designBounds && this.props.designBounds.weightBound &&
          <div className="BoundFilters-rows">
            <Row>
              Weight
            </Row>
            <Row>
              <Col md={3}>
                <InputGroup>
                  <Input
                    disabled={true}
                    type="text"
                    value={this.props.designBounds.weightBound.min}
                  />
                  <InputGroupAddon addonType="append">kg</InputGroupAddon>
                </InputGroup>
              </Col>
              <Col md={6}>
                <RangeSlider
                  disabled={true}
                  domain={[this.state.weightBound.min, this.state.weightBound.max]}
                  values={[this.props.designBounds.weightBound.min, this.props.designBounds.weightBound.max]}
                  step={
                    (this.state.weightBound.max - this.state.weightBound.min) / BoundFilters.STEPS_NUM
                  }
                />
              </Col>
              <Col md={3}>
                <InputGroup>
                  <Input
                    type="text"
                    value={this.state.weightMax}
                    invalid={isUndefined(this.state.weightMax) || !BoundFilters.validateFilter(convertStringToNumber(this.state.weightMax), this.props.designBounds.weightBound.min, this.state.weightBound.max)}
                    onKeyDown={this.handleOnKeyDown}
                    onBlur={this.handleOnBlur}
                    onChange={event => this.handleOnChangeWeightFilter(event.target.value)}
                  />
                  <InputGroupAddon addonType="append">kg</InputGroupAddon>
                </InputGroup>
              </Col>
            </Row>
          </div>
        }
      </Container>
    );
  }
}

export default BoundFilters;
