import axios from "axios";
import { LOCAL_STORAGE_TOKEN_KEY } from "./store/auth/actions";

export const LOGIN_URL = "/api/login";
export const USER_URL = "/api/user";
export const SIGN_UP_URL = "/api/signup";
export const DESIGNS_URL = "/api/designs";
export const DESIGN_BOUNDS_URL = "/api/design_bounds";
export const DESIGN_INQUIRY_URL = "api/requestDesign";

const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

API.interceptors.request.use(
  config => {
    let token = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },

  error => {
    return Promise.reject(error);
  }
);

export default API;
