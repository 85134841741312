import React from "react";
import "./RequestInfo.scss";
import { Container, Row, Col } from "reactstrap";
import { DesignBoundsRequest } from "../../store/designs/request/types";
import {
    getMftTypeIcon,
    getConductorTypeIcon,
    getCoreTypeIcon
} from "../../store/utils";

export interface RequestInfoProps {
    request: DesignBoundsRequest;
}

class RequestInfo extends React.Component<RequestInfoProps> {
    public render() {
        let nominalPower;
        if (this.props.request.nominalPower !== null) {
            nominalPower = this.props.request.nominalPower / 1000;
        }
        return (
            <div className="RequestInfo-table">
                <Container>
                    <Row>
                        <Col md={10}>MFT Type:</Col>
                        <Col md={2}>
                            <img src={getMftTypeIcon(this.props.request.mftType, true)} alt="" className="RequestInfo-smallIcon float-right"></img>
                            {/* <strong className="float-right">{this.props.request.mftType}</strong> */}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={10}>Conductor Type:</Col>
                        <Col md={2}>
                            <img src={getConductorTypeIcon(this.props.request.conductorType, true)} alt="" className="RequestInfo-smallIcon float-right"></img>
                            {/* <strong className="float-right">{this.props.request.conductorType}</strong> */}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={10}>Core Type:</Col>
                        <Col md={2}>
                            <img src={getCoreTypeIcon(this.props.request.coreType, true)} alt="" className="RequestInfo-smallIcon float-right"></img>
                            {/* <strong className="float-right">{this.props.request.coreType}</strong> */}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={10}>Nominal Power:</Col>
                        <Col md={2}>
                            <strong className="float-right">{nominalPower}kW</strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={10}>Switch Frequency:</Col>
                        <Col md={2}>
                            <strong className="float-right">{this.props.request.switchFrequency}kHz</strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={10}>Input Voltage:</Col>
                        <Col md={2}>
                            <strong className="float-right">{this.props.request.inputVoltage}V</strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={10}>Output Voltage:</Col>
                        <Col md={2}>
                            <strong className="float-right">{this.props.request.outputVoltage}V</strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={10}>First Winding Insulation Rating:</Col>
                        <Col md={2}>
                            <strong className="float-right">{this.props.request.firstWIRatingReducer}kV</strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={10}>Second Winding Insulation Rating:</Col>
                        <Col md={2}>
                            <strong className="float-right">{this.props.request.secondWIRatingReducer}kV</strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={10}>First Insulation Type:</Col>
                        <Col md={2}>
                            <strong className="float-right">{this.props.request.firstInsulationType}</strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={10}>Second Insulation Type:</Col>
                        <Col md={2}>
                            <strong className="float-right">{this.props.request.secondInsulationType}</strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={10}>Core Material:</Col>
                        <Col md={2}>
                            <strong className="float-right">{this.props.request.coreMaterial}</strong>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default RequestInfo;
