import React from "react";
import "../PageNotFound/PageNotFound.scss";

const PageNotFound: React.SFC = () => {
  return (
      <div className="PageNotFound">Page Not Found</div>
  );
};

export default PageNotFound;
