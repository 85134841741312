// Design inquiry
export interface DesignInquiryState {
  pending: boolean;
  error?: Error;
}


export const REQUEST_DESIGN_INQUIRY = "REQUEST_DESIGN_INQUIRY";
export const RECEIVE_DESIGN_INQUIRY = "RECEIVE_DESIGN_INQUIRY";

export interface DesignInquiryState {
  pending: boolean;
  error?: Error;
}

export interface RequestDesignInquiryAction {
  type: typeof REQUEST_DESIGN_INQUIRY;
}

export interface ReceiveDesignInquiryDataAction {
  type: typeof RECEIVE_DESIGN_INQUIRY;
  payload?: object;
  error?: boolean;
}

export type DesignInquiryActions = RequestDesignInquiryAction | ReceiveDesignInquiryDataAction