import { ThunkDispatch } from "redux-thunk";
import { Dispatch } from "redux";
import logger from "../../logger";
import {
  REQUEST_DESIGNS,
  RequestDesignsAction,
  RECEIVE_DESIGNS,
  ReceiveDesignsAction,
  DesignsActions,
  Designs
} from "./types";
import { AppState } from "..";
import API, { DESIGNS_URL } from "../../api";
import { DesignsRequest } from "./types";
import { DesignBounds, DesignBoundsActions } from "./bounds/types";
import { recieveDesignBounds } from "./bounds/actons";

function requestData(): RequestDesignsAction {
  return {
    type: REQUEST_DESIGNS
  };
}

export function receiveData(
  error: boolean,
  payload?: object
): ReceiveDesignsAction {
  return {
    type: RECEIVE_DESIGNS,
    payload: payload,
    error: error
  };
}

function handleErrorsOnDesignsRequest(dispatch: Dispatch, error: any) {
  if (error.response) {
    logger.error(
      "The request was made and the server responded with a status code, that falls out of the range of 2xx: ",
      JSON.stringify(error.response)
    );
    var message = "Unknown eror."
    if ("msg" in error.response.data) {
      dispatch(receiveData(true, new Error(error.response.data.msg)));
    } else {
      dispatch(receiveData(true, new Error(message)));
    }
  } else if (error.request) {
    logger.error(
      "The request was made but no response was received: ",
      JSON.stringify(error.request)
    );
    dispatch(receiveData(true, new Error(error.request)));
  } else {
    logger.error(
      "Something happened in setting up the request that triggered an Error: ",
      JSON.stringify(error.message)
    );
    dispatch(receiveData(true, new Error(error)));
  }
}

function fetchDesigns(request: DesignsRequest) {
  return (dispatch: Dispatch<DesignsActions | DesignBoundsActions>) => {
    dispatch(requestData());
    logger.info("Request for designs: " + JSON.stringify(request));
    return API.post(DESIGNS_URL, request).then(
      response => {
        logger.info(
          "Response on requesting designs: " + JSON.stringify(response)
        );
        dispatch(receiveData(false, response.data));

        let designs: Designs = {
          count: response.data.count,
          Designs: response.data.Designs
        };
        dispatch(receiveData(false, designs));

        let designBounds: DesignBounds = {
          coreTempBound: response.data.coreTempBound,
          windingTempBound: response.data.windingTempBound,
          magnetizingInductBound: response.data.magnetizingInductBound,
          leakageInductBound: response.data.leakageInductBound,
          effeciencyBound: response.data.effeciencyBound,
          widthBound: response.data.widthBound,
          lengthBound: response.data.lengthBound,
          heightBound: response.data.heightBound,
          volumeBound: response.data.volumeBound,
          weightBound: response.data.weightBound
        };
        dispatch(recieveDesignBounds(false, designBounds));
      },
      error => {
        logger.error("Error after requesting designs!");
        handleErrorsOnDesignsRequest(dispatch, error);
      }
    );
  };
}

function shouldFetchDesigns(state: AppState) {
  const data = state.designs;
  return !data || !data.isFetching;
}

export function fetchDesignsIfNeeded() {
  return (
    dispatch: ThunkDispatch<AppState, null, DesignsActions>,
    getState: () => AppState
  ) => {
    if (shouldFetchDesigns(getState())) {
      let request: DesignsRequest = {
        ...getState().request,
        ...getState().designBounds.designBounds
      };
      return dispatch(fetchDesigns(request));
    }
    return Promise.resolve();
  };
}
