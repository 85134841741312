export interface SignUpState {
  pending: boolean;
  error?: Error;
}

export const REQUEST_SIGNUP = "REQUEST_SIGNUP";
export const RECEIVE_SIGNUP_DATA = "RECEIVE_SIGNUP_DATA";

export interface RequestSignUpAction {
  type: typeof REQUEST_SIGNUP;
}

export interface ReceiveSignUpDataAction {
  type: typeof RECEIVE_SIGNUP_DATA;
  payload?: object;
  error?: boolean;
}

export type SignUpActions = RequestSignUpAction | ReceiveSignUpDataAction;
