import React from "react";
import "./SearchPage.scss";
import Loading from "../Loading/Loading";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
  UncontrolledAlert,
  Form,
  FormGroup,
  Label,
  Col,
  Row,
  Input,
  ButtonGroup,
  Button,
  InputGroup,
  InputGroupAddon,
  Container,
  Media,
  Collapse
} from "reactstrap";
import { AppState } from "../store";
import {
  CoreMaterial,
  InsulationType,
  MftType,
  ConductorType,
  CoreType
} from "../store/designs/types";
import { fetchDesignBoundsIfNeeded } from "../store/designs/bounds/actons";
import {
  changeNominalPower,
  changeInputVoltage,
  changeOutputVoltage,
  changeSwitchFreq,
  changeFirstWIRating,
  changeSecondWIRating,
  changeCoreMaterial,
  changeFirstInsulationType,
  changeSecondInsulationType,
  changeMftType,
  changeConductorType,
  changeCoreType
} from "../store/designs/request/actons";
import mft_type_c_coming_soon from "../assets/mft_type_c_coming_soon.png";
import mft_type_core_coming_soon from "../assets/mft_type_core_coming_soon.png";
import conductor_type_foil_coming_soon from "../assets/conductor_type_foil_coming_soon.png";
import conductor_type_bar_coming_soon from "../assets/conductor_type_bar_coming_soon.png";
import core_type_goes_coming_soon from "../assets/core_type_goes_coming_soon.png";
import core_type_nanocrystalline_coming_soon from "../assets/core_type_nanocrystalline_coming_soon.png";
import {
  getMftTypeIcon,
  getConductorTypeIcon,
  getCoreTypeIcon
} from "../store/utils";

import { convertNumberToString, convertStringToNumber } from "../ResultPage/utils";

export interface SearchPageProps {
  nominalPower: number | null;
  inputVoltage: number | null;
  outputVoltage: number | null;
  switchFrequency: number | null;
  firstWIRatingReducer: number | null;
  secondWIRatingReducer: number | null;
  coreMaterial: CoreMaterial;
  firstInsulationType: InsulationType;
  secondInsulationType: InsulationType;
  mftType: MftType;
  conductorType: ConductorType;
  coreType: CoreType;
  changeNominalPower: typeof changeNominalPower;
  changeInputVoltage: typeof changeInputVoltage;
  changeOutputVoltage: typeof changeOutputVoltage;
  changeSwitchFreq: typeof changeSwitchFreq;
  changeFirstWIRating: typeof changeFirstWIRating;
  changeSecondWIRating: typeof changeSecondWIRating;
  changeCoreMaterial: typeof changeCoreMaterial;
  changeFirstInsulationType: typeof changeFirstInsulationType;
  changeSecondInsulationType: typeof changeSecondInsulationType;
  changeMftType: typeof changeMftType;
  changeConductorType: typeof changeConductorType;
  changeCoreType: typeof changeCoreType;
  isFetching: boolean;
  error?: Error;
  fetchDesignBoundsIfNeeded: () => void;
}

enum SelectedView {
  MftType = 0,
  ConductorType = 1,
  CoreType = 2,
  None = 3,
}

export interface SearchPageState {
  prevIsFetching: boolean;
  mftType: string;
  widningType: string;
  selectedView: SelectedView;
  mftTypeCoreIconSelected: boolean;
  mftTypeShellIconSelected: boolean;
  mftTypeCtypeIconSelected: boolean;
  conductorTypeIconLitzWireSelected: boolean;
  coreTypeIconFerriteSelected: boolean;
}



export class SearchPage extends React.Component<SearchPageProps, SearchPageState>{
  static defaultProps = {
    firstWIRatingReducer: 5,
    secondWIRatingReducer: 0,
  }

  public constructor(props: SearchPageProps) {
    super(props);
    this.state = {
      prevIsFetching: false,
      mftType: "",
      widningType: "",
      selectedView: SelectedView.MftType,
      mftTypeCoreIconSelected: false,
      mftTypeShellIconSelected: false,
      mftTypeCtypeIconSelected: false,
      conductorTypeIconLitzWireSelected: false,
      coreTypeIconFerriteSelected: false
    };
    this.props.changeFirstWIRating(5);
    this.props.changeSecondWIRating(0);
    this.props.changeNominalPower(10000);
  }

  public componentDidUpdate(prevProps: SearchPageProps) {
    if (this.props.isFetching !== prevProps.isFetching) {
      this.setState({ prevIsFetching: prevProps.isFetching });
    }
  }

  private canBeSubmitted = () =>
    this.props.nominalPower !== null &&
    this.props.inputVoltage !== null &&
    this.props.outputVoltage !== null &&
    this.props.firstWIRatingReducer !== null &&
    this.props.secondWIRatingReducer !== null &&
    this.props.switchFrequency !== null &&
    this.props.mftType !== MftType.UNDEFINED &&
    this.props.conductorType !== ConductorType.UNDEFINED &&
    this.props.coreType !== CoreType.UNDEFINED;

  private handleSubmit = (event: any) => {
    event.preventDefault();
    if (!this.canBeSubmitted()) {
      return;
    }
    this.props.fetchDesignBoundsIfNeeded();
    window.scrollTo(0, 0);
  };

  private nextSelectedView() {
    setTimeout(() => {
      var nextView = (this.state.selectedView + 1) % 4;
      this.setState({ selectedView: nextView });
    }, 100)

  }

  private onClickMftType(mftType: MftType) {
    this.props.changeMftType(mftType);
    this.nextSelectedView();
  }

  private onClickConductorType(conductorType: ConductorType) {
    this.props.changeConductorType(conductorType);
    this.nextSelectedView();
  }

  private onClickCoreType(coreType: CoreType) {
    this.props.changeCoreType(coreType);
    this.nextSelectedView();
  }

  public render() {
    if (this.props.isFetching) {
      return <Loading message="Please wait while we are generating your designs" style={{ marginTop: "300px" }}/>;
    } else if (
      !this.props.isFetching &&
      this.state.prevIsFetching &&
      !this.props.error
    ) {
      return <Redirect push to="/results" />;
    }
    return (
      <Container className="SearchPageContainer">
        <h1 className="SearchPageHeaderMain SearchPageMarginLeftAndRight">Design your transformer <br /></h1>
        {this.props.error && (
          <UncontrolledAlert color="danger">
            Error: <strong>{this.props.error.message}</strong>
          </UncontrolledAlert>
        )}
        <Form onSubmit={this.handleSubmit} className="SearchPageMarginLeftAndRight">
          <Row>
            <Col onClick={() => this.setState({ selectedView: SelectedView.MftType })} className="searchClickableView">
              <h4 className="searchPageHeader">
                MFT type
                <img src={getMftTypeIcon(this.props.mftType, true)} alt="" className="searchSmallIcon"></img>
              </h4>
              <br />
            </Col>
          </Row>
          <Row>
            <Col />
            <Collapse isOpen={this.state.selectedView === SelectedView.MftType}>
              <Col md="auto" >
                <ButtonGroup >
                  <Button className="SearchPageButtonImage" onClick={() => this.onClickMftType(MftType.SHELL)} >
                    <Media id="MFTType_Shell" src={getMftTypeIcon(MftType.SHELL, this.props.mftType === MftType.SHELL || this.state.mftTypeCoreIconSelected)}
                      className="SearchPageImage align-content-center"
                      onMouseEnter={() => this.setState({ mftTypeCoreIconSelected: true })}
                      onMouseLeave={() => this.setState({ mftTypeCoreIconSelected: false })}
                    />
                  </Button>
                  <Button className="SearchPageButtonImage" onClick={() => this.onClickMftType(MftType.CORE)} active={this.props.mftType === MftType.CORE} disabled={true}>
                    <Media src={mft_type_core_coming_soon} className="SearchPageImage align-content-center" />
                  </Button>
                  <Button className="SearchPageButtonImage" onClick={() => this.onClickMftType(MftType.CTYPE)} active={this.props.mftType === MftType.CTYPE} disabled={true}>
                    <Media src={mft_type_c_coming_soon} className="SearchPageImage align-content-center" />
                  </Button>
                </ButtonGroup>
                <br />
              </Col>
            </Collapse>
            <Col />
          </Row>
          <Row>
            <Col>
              <div className="whiteDivider">
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="dividerEmpty">
              </div>
            </Col>
          </Row>
          <Row>
            <Col onClick={() => this.setState({ selectedView: SelectedView.ConductorType })} className="searchClickableView">
              <h4 className="searchPageHeader">
                Conductor type
                <img src={getConductorTypeIcon(this.props.conductorType, true)} alt="" className="searchSmallIcon"></img>
              </h4>
              <br />
            </Col>
          </Row>
          <Row>
            <Col />
            <Collapse isOpen={this.state.selectedView === SelectedView.ConductorType}>
              <Col md="auto">
                <ButtonGroup>
                  <Button className="SearchPageButtonImage"
                    onClick={() => this.onClickConductorType(ConductorType.LITZ)}
                    active={this.props.conductorType === ConductorType.LITZ}
                  >
                    <Media id="ConductorType_LITZ" src={getConductorTypeIcon(ConductorType.LITZ, this.props.conductorType === ConductorType.LITZ || this.state.conductorTypeIconLitzWireSelected)}
                      className="SearchPageImage align-content-center"
                      onMouseEnter={() => this.setState({ conductorTypeIconLitzWireSelected: true })}
                      onMouseLeave={() => this.setState({ conductorTypeIconLitzWireSelected: false })}
                    />
                  </Button>
                  <Button className="SearchPageButtonImage"
                    onClick={() => this.onClickConductorType(ConductorType.BAR)}
                    active={this.props.conductorType === ConductorType.BAR}
                    disabled={true}>
                    <Media src={conductor_type_bar_coming_soon} className="SearchPageImage align-content-center" />
                  </Button>
                  <Button className="SearchPageButtonImage"
                    onClick={() => this.onClickConductorType(ConductorType.FOIL)}
                    active={this.props.conductorType === ConductorType.FOIL}
                    disabled={true} >
                    <Media src={conductor_type_foil_coming_soon} className="SearchPageImage align-content-center" />
                  </Button>
                </ButtonGroup>
              </Col>
            </Collapse>
            <Col />
          </Row>
          <Row>
            <Col>
              <div className="whiteDivider">
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="dividerEmpty">
              </div>
            </Col>
          </Row>
          <Row>
            <Col onClick={() => this.setState({ selectedView: SelectedView.CoreType })} className="searchClickableView">
              <h4 className="searchPageHeader">
                Core type
                <img src={getCoreTypeIcon(this.props.coreType, true)} alt="" className="searchSmallIcon"></img>
              </h4>
              <br />
            </Col>
          </Row>
          <Row>
            <Col />
            <Collapse isOpen={this.state.selectedView === SelectedView.CoreType}>
              <Col md="auto">
                <ButtonGroup>
                  <Button className="SearchPageButtonImage"
                    onClick={() => this.onClickCoreType(CoreType.FERRITE)}
                    active={this.props.coreType === CoreType.FERRITE}>
                    <Media id="CoreTypeFERRITE" src={getCoreTypeIcon(CoreType.FERRITE, this.props.coreType === CoreType.FERRITE || this.state.coreTypeIconFerriteSelected)}
                      className="SearchPageImage align-content-center"
                      onMouseEnter={() => this.setState({ coreTypeIconFerriteSelected: true })}
                      onMouseLeave={() => this.setState({ coreTypeIconFerriteSelected: false })}
                    />
                  </Button>
                  <Button className="SearchPageButtonImage" onClick={() => this.onClickCoreType(CoreType.GOES)} active={this.props.coreType === CoreType.GOES} >
                    <Media src={core_type_goes_coming_soon} className="SearchPageImage align-content-center" />
                  </Button>
                  <Button className="SearchPageButtonImage" onClick={() => this.onClickCoreType(CoreType.NANOCRYSTALLINE)} active={this.props.coreType === CoreType.NANOCRYSTALLINE}>
                    <Media src={core_type_nanocrystalline_coming_soon} className="SearchPageImage align-content-center" />
                  </Button>
                </ButtonGroup>
              </Col>
            </Collapse>
            <Col />
          </Row>
          <Row>
            <Col>
              <div className="whiteDivider">
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="dividerEmpty">
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="searchClickableView">
              <h4 className="searchPageHeader searchPageSpecification">
                Specifications
              </h4>
              <br />
            </Col>
          </Row>
          {/* Nominal POWER Row */}
          <Row>
            <Col md={6}>
              <FormGroup className="searchPageFormGroup">
                <Label for="nominalPower">Nominal Power</Label>
                <InputGroup className="SearchPageInputGroup">
                  <Input
                    type="select"
                    name="nominalPower"
                    id="nominalPower"
                    defaultValue={convertNumberToString(convertWattsToKiloWatts(
                      this.props.nominalPower
                    ))}
                    invalid={
                      this.props.nominalPower !== null &&
                      isNaN(this.props.nominalPower)
                    }
                    onChange={e =>
                      this.props.changeNominalPower(
                        convertKiloWattsToWatts(convertStringToNumber(e.target.value))
                      )
                    }
                  >
                    {Object.values([10, 50, 100, 200]).map((value, index) => (
                      <option key={index}>{value}</option>
                    ))}
                  </Input>
                  <InputGroupAddon className="SearchPageInputGroupAddon" addonType="append">kW</InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="searchPageFormGroup">
                <Label for="switchFrequency">Switch Frequency [0.5- 50] kHz</Label>
                <InputGroup className="SearchPageInputGroup">
                  <Input
                    type="number"
                    min="0.5"
                    max="50"
                    step="0.01"
                    name="switchFrequency"
                    id="switchFrequency"
                    defaultValue={convertNumberToString(
                      this.props.switchFrequency
                    )}
                    invalid={
                      this.props.switchFrequency !== null &&
                      isNaN(this.props.switchFrequency)
                    }
                    onChange={e =>
                      this.props.changeSwitchFreq(
                        convertStringToNumber(e.target.value)
                      )
                    }
                  />
                  <InputGroupAddon className="SearchPageInputGroupAddon" addonType="append">kHz</InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          {/* End Nominal POWER Row */}
          <Row>
            <Col md={6}>
              <FormGroup className="searchPageFormGroup">
                <Label for="inputVoltage">Input Voltage</Label>
                <InputGroup className="SearchPageInputGroup">
                  <Input
                    type="text"
                    name="inputVoltage"
                    id="inputVoltage"
                    defaultValue={convertNumberToString(
                      this.props.inputVoltage
                    )}
                    invalid={
                      this.props.inputVoltage !== null &&
                      isNaN(this.props.inputVoltage)
                    }
                    onChange={e =>
                      this.props.changeInputVoltage(
                        convertStringToNumber(e.target.value)
                      )
                    }
                  />
                  <InputGroupAddon className="SearchPageInputGroupAddon" addonType="append">V</InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="searchPageFormGroup">
                <Label for="outputVoltage">Output Voltage</Label>
                <InputGroup className="SearchPageInputGroup">
                  <Input
                    type="text"
                    name="outputVoltage"
                    id="outputVoltage"
                    defaultValue={convertNumberToString(
                      this.props.outputVoltage
                    )}
                    invalid={
                      this.props.outputVoltage !== null &&
                      isNaN(this.props.outputVoltage)
                    }
                    onChange={e =>
                      this.props.changeOutputVoltage(
                        convertStringToNumber(e.target.value)
                      )
                    }
                  />
                  <InputGroupAddon className="SearchPageInputGroupAddon" addonType="append">V</InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>

          <Row >
            <Col md={6}>
              <FormGroup className="searchPageFormGroup">
                <Label for="firstWIRatingReducer">
                  First Winding Insulation Rating
                  </Label>
                <InputGroup className="SearchPageInputGroup">
                  <Input
                    type="select"
                    name="firstWIRatingReducer"
                    id="firstWIRatingReducer"
                    defaultValue={convertNumberToString(
                      this.props.firstWIRatingReducer
                    )}
                    invalid={
                      this.props.firstWIRatingReducer !== null &&
                      isNaN(this.props.firstWIRatingReducer)
                    }
                    onChange={e =>
                      this.props.changeFirstWIRating(
                        convertStringToNumber(e.target.value)
                      )
                    }
                  >
                    {Object.values([5, 10, 15, 20, 25]).map((value, index) => (
                      <option key={index}>{value}</option>
                    ))}
                  </Input>
                  <InputGroupAddon className="SearchPageInputGroupAddon" addonType="append">kV</InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="searchPageFormGroup">
                <Label for="firstInsulationType"> First Insulation Type</Label>
                <Input className="SearchPageInputGroup"
                  type="select"
                  name="firstInsulationType"
                  id="firstInsulationType"
                  value={this.props.firstInsulationType}
                  onChange={e =>
                    this.props.changeFirstInsulationType(e.target
                      .value as InsulationType)
                  }
                >
                  {Object.values(InsulationType).map((value, index) => (
                    <option key={index}>{value}</option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup className="searchPageFormGroup">
                <Label for="secondWIRatingReducer">
                  Second Winding Insulation Rating
                  </Label>
                <InputGroup className="SearchPageInputGroup">
                  <Input
                    type="text"
                    name="secondWIRatingReducer"
                    id="secondWIRatingReducer"
                    defaultValue={convertNumberToString(this.props.secondWIRatingReducer)}
                    invalid={
                      this.props.secondWIRatingReducer !== null &&
                      isNaN(this.props.secondWIRatingReducer)
                    }
                    disabled={true}
                    onChange={e =>
                      this.props.changeSecondWIRating(
                        convertStringToNumber(e.target.value)
                      )
                    }
                  />
                  <InputGroupAddon className="SearchPageInputGroupAddon" addonType="append">kV</InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="searchPageFormGroup">
                <Label for="secondInsulationType">
                  Second Insulation Type
                  </Label>
                <Input className="SearchPageInputGroup"
                  type="select"
                  name="secondInsulationType"
                  id="secondInsulationType"
                  value={this.props.secondInsulationType}
                  onChange={e =>
                    this.props.changeSecondInsulationType(e.target
                      .value as InsulationType)
                  }
                >
                  {Object.values(InsulationType).map((value, index) => (
                    <option key={index}>{value}</option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup className="searchPageFormGroup">
                <Label for="coreMaterial">Core Material</Label>
                <Input className="SearchPageInputGroup"
                  type="select"
                  name="coreMaterial"
                  id="coreMaterial"
                  value={this.props.coreMaterial}
                  disabled={true}
                  onChange={e =>
                    this.props.changeCoreMaterial(e.target
                      .value as CoreMaterial)
                  }
                >
                  {Object.values(CoreMaterial).map((value, index) => (
                    <option key={index}>{value}</option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col></Col>
            <Col md="auto">
              <Button className="SearchPageSubmitButton" disabled={!this.canBeSubmitted()}>Submit</Button>
            </Col>
            <Col></Col>
          </Row>
        </Form>
      </Container>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  nominalPower: state.request.nominalPower,
  inputVoltage: state.request.inputVoltage,
  outputVoltage: state.request.outputVoltage,
  switchFrequency: state.request.switchFrequency,
  firstWIRatingReducer: state.request.firstWIRatingReducer,
  secondWIRatingReducer: state.request.secondWIRatingReducer,
  coreMaterial: state.request.coreMaterial,
  firstInsulationType: state.request.firstInsulationType,
  secondInsulationType: state.request.secondInsulationType,
  mftType: state.request.mftType,
  conductorType: state.request.conductorType,
  coreType: state.request.coreType,
  isFetching: state.designBounds.isFetching,
  error: state.designBounds.error
});

const convertKiloWattsToWatts = (kiloWatts: number) => {
  if (!kiloWatts || isNaN(kiloWatts)) {
    return kiloWatts;
  }
  return kiloWatts * 1000;
}

const convertWattsToKiloWatts = (watts: number | null) => {
  if (!watts || isNaN(watts)) {
    return watts;
  }
  return watts / 1000;
}

export default connect(
  mapStateToProps,
  {
    changeNominalPower,
    changeInputVoltage,
    changeOutputVoltage,
    changeSwitchFreq,
    changeFirstWIRating,
    changeSecondWIRating,
    changeCoreMaterial,
    changeFirstInsulationType,
    changeSecondInsulationType,
    changeMftType,
    changeConductorType,
    changeCoreType,
    fetchDesignBoundsIfNeeded
  }
)(SearchPage);
