export function convertStringToNumber(value: string) {
    return value !== "" ? Number(value) : NaN;
}

export function convertNumberToString(value: number | null) {
    return value !== null ? value.toString() : undefined;
}

export function roundMax3Decimals(num: number): number {
    return Math.round(num * 1000) / 1000;
}

export function formatSI(num: number, unit: string): string[] {
    if (num === 0) {
        return ['0', unit];
    }
    let sig = Math.abs(num); // significand
    let exponent = 0;
    while (sig >= 1000 && exponent < 24) {
        sig /= 1000;
        exponent += 3;
    }
    while (sig < 1 && exponent > -24) {
        sig *= 1000;
        exponent -= 3;
    }

    const signPrefix = num < 0 ? '-' : '';
    if (sig > 1000) { // exponent == 24
        // significand can be arbitrarily long
        return [signPrefix + sig.toFixed(0), PREFIXES[exponent] + unit];
    }
    return [signPrefix + parseFloat(sig.toPrecision(3)), PREFIXES[exponent] + unit];
}

const PREFIXES: { [key: string]: string } = {
    '24': 'Y',
    '21': 'Z',
    '18': 'E',
    '15': 'P',
    '12': 'T',
    '9': 'G',
    '6': 'M',
    '3': 'k',
    '0': '',
    '-3': 'm',
    '-6': 'µ',
    '-9': 'n',
    '-12': 'p',
    '-15': 'f',
    '-18': 'a',
    '-21': 'z',
    '-24': 'y'
};