import { combineReducers, Reducer } from "redux";
import {
  ChangeNominalPowerAction,
  CHANGE_NOMINAL_POWER,
  ChangeInputVoltageAction,
  CHANGE_INPUT_VOLTAGE,
  ChangeOutputVoltageAction,
  CHANGE_OUTPUT_VOLTAGE,
  ChangeSwitchFreqAction,
  CHANGE_SWITCH_FREQ,
  ChangeFirstWIRatingAction,
  CHANGE_FIRST_W_I_RATING,
  ChangeSecondWIRatingAction,
  CHANGE_SECOND_W_I_RATING,
  ChangeCoreMaterialAction,
  CHANGE_CORE_MATERIAL,
  ChangeFirstInsulationTypeAction,
  CHANGE_FIRST_INSULATION_TYPE,
  ChangeSecondInsulationTypeAction,
  CHANGE_SECOND_INSULATION_TYPE,
  ChangeMftTypeAction,
  CHANGE_MFT_TYPE,
  ChangeConductorTypeAction,
  CHANGE_CONDUCTOR_TYPE,
  ChangeCoreTypeAction,
  CHANGE_CORE_TYPE,
  DesignBoundsRequest
} from "./types";
import { CoreMaterial, InsulationType, MftType, ConductorType, CoreType } from "../types";

function nominalPowerReducer(
  state: number | null = null,
  action: ChangeNominalPowerAction
) {
  switch (action.type) {
    case CHANGE_NOMINAL_POWER:
      return action.payload;
    default:
      return state;
  }
}

function inputVoltageReducer(
  state: number | null = null,
  action: ChangeInputVoltageAction
) {
  switch (action.type) {
    case CHANGE_INPUT_VOLTAGE:
      return action.payload;
    default:
      return state;
  }
}

function outputVoltageReducer(
  state: number | null = null,
  action: ChangeOutputVoltageAction
) {
  switch (action.type) {
    case CHANGE_OUTPUT_VOLTAGE:
      return action.payload;
    default:
      return state;
  }
}

function switchFreqReducer(
  state: number | null = null,
  action: ChangeSwitchFreqAction
) {
  switch (action.type) {
    case CHANGE_SWITCH_FREQ:
      return action.payload;
    default:
      return state;
  }
}

function firstWIRatingReducer(
  state: number | null = null,
  action: ChangeFirstWIRatingAction
) {
  switch (action.type) {
    case CHANGE_FIRST_W_I_RATING:
      return action.payload;
    default:
      return state;
  }
}

function secondWIRatingReducer(
  state: number | null = null,
  action: ChangeSecondWIRatingAction
) {
  switch (action.type) {
    case CHANGE_SECOND_W_I_RATING:
      return action.payload;
    default:
      return state;
  }
}

function coreMaterialReducer(
  state = CoreMaterial.SI_FERRITE,
  action: ChangeCoreMaterialAction
): CoreMaterial {
  switch (action.type) {
    case CHANGE_CORE_MATERIAL:
      return action.payload;
    default:
      return state;
  }
}

function firstInsulationTypeReducer(
  state = InsulationType.AIR,
  action: ChangeFirstInsulationTypeAction
): InsulationType {
  switch (action.type) {
    case CHANGE_FIRST_INSULATION_TYPE:
      return action.payload;
    default:
      return state;
  }
}

function secondInsulationTypeReducer(
  state = InsulationType.AIR,
  action: ChangeSecondInsulationTypeAction
): InsulationType {
  switch (action.type) {
    case CHANGE_SECOND_INSULATION_TYPE:
      return action.payload;
    default:
      return state;
  }
}

function mftTypeReducer(
  state = MftType.UNDEFINED,
  action: ChangeMftTypeAction
): MftType {
  switch (action.type) {
    case CHANGE_MFT_TYPE:
      return action.payload;
    default:
      return state;
  }
}

function conductorTypeReducer(
  state = ConductorType.UNDEFINED,
  action: ChangeConductorTypeAction
): ConductorType {
  switch (action.type) {
    case CHANGE_CONDUCTOR_TYPE:
      return action.payload;
    default:
      return state;
  }
}

function coreTypeReducer(
  state = CoreType.UNDEFINED,
  action: ChangeCoreTypeAction
): CoreType {
  switch (action.type) {
    case CHANGE_CORE_TYPE:
      return action.payload;
    default:
      return state;
  }
}

export const requestReducer: Reducer<DesignBoundsRequest> = combineReducers({
  nominalPower: nominalPowerReducer,
  inputVoltage: inputVoltageReducer,
  outputVoltage: outputVoltageReducer,
  switchFrequency: switchFreqReducer,
  firstWIRatingReducer: firstWIRatingReducer,
  secondWIRatingReducer: secondWIRatingReducer,
  coreMaterial: coreMaterialReducer,
  firstInsulationType: firstInsulationTypeReducer,
  secondInsulationType: secondInsulationTypeReducer,
  mftType: mftTypeReducer,
  conductorType: conductorTypeReducer,
  coreType: coreTypeReducer
});
