import "bootstrap/dist/css/bootstrap.min.css";
// Put any other imports below so that CSS from your
// components takes precedence over default styles.
import React from "react";
import WebFont from 'webfontloader';
import ReactDOM from "react-dom";
import "./index.scss";
import * as serviceWorker from "./serviceWorker";
import configureStore from "./store";
import { Provider } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  RouteProps
} from "react-router-dom";
import HomePage from "./HomePage/HomePage";
import ResultPage from "./ResultPage/ResultPage";
import SearchPage from "./SearchPage/SearchPage";
import LoginPage from "./LoginPage/LoginPage";
import PageNotFound from "./PageNotFound/PageNotFound";
import SignUpPage from "./SignUpPage/SignUpPage";
import WelcomePage from './WelcomePage/WelcomePage';
import { LOCAL_STORAGE_TOKEN_KEY } from "./store/auth/actions";
import NavigationBar from "./NavigationBar/NavigationBar";
import Footer from "./Footer/Footer"

const store = configureStore();

const isAuth = () => localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY) !== null;

// Webfontloader
WebFont.load({
  google: {
    families: ['Lato', 'sans-serif']
  }
});



interface PrivateRouteProps extends RouteProps {
  component: React.ComponentType<any>;
}

const PrivateRoute = (props: PrivateRouteProps) => {
  let { component: Component, ...rest } = props;
  return (
    <Route
      {...rest}
      render={props => {
        return isAuth() ? <Component {...props} /> : <Redirect to="/login" />;
      }}
    />
  );
};

const Root = () => (
  <Provider store={store}>
    <NavigationBar />
    <div className="content">
      <Router>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route
            path="/login"
            render={props =>
              !isAuth() ? <LoginPage {...props} /> : <Redirect to="/demo" />
            }
          />
          <Route path="/register" component={SignUpPage} />
          <Route path="/welcome" component={WelcomePage} />
          <PrivateRoute path="/demo" component={SearchPage} />
          <PrivateRoute path="/results" component={ResultPage} />
          <Route component={PageNotFound} />
        </Switch>
      </Router>
    </div>
    <Footer />
  </Provider>
);

ReactDOM.render(<Root />, document.getElementById("root") as HTMLElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
