import {
  RequestLoginAction,
  ReceiveLoginDataAction,
  REQUEST_LOGIN,
  RECEIVE_LOGIN_DATA,
  LoginActions
} from "./types";
import { AppState } from "..";
import { Dispatch } from "redux";
import logger from "../../logger";
import API, { LOGIN_URL, USER_URL } from "../../api";
import { ThunkDispatch } from "redux-thunk";

export const LOCAL_STORAGE_TOKEN_KEY = "token";

function requestLogin(): RequestLoginAction {
  return {
    type: REQUEST_LOGIN
  };
}

function recieveLoginData(
  error: boolean,
  payload?: object
): ReceiveLoginDataAction {
  return {
    type: RECEIVE_LOGIN_DATA,
    payload: payload,
    error: error
  };
}

function handleErrorsOnLogin(dispatch: Dispatch, error: any) {
  if (error.response) {
    logger.error(
      "The request was made and the server responded with a status code, that falls out of the range of 2xx: ",
      JSON.stringify(error.response)
    );
    var message = "Unknown eror."
    if ("msg" in error.response.data) {
      dispatch(recieveLoginData(true, new Error(error.response.data.msg)));
    } else {
      dispatch(recieveLoginData(true, new Error(message)));
    }
  } else if (error.request) {
    logger.error(
      "The request was made but no response was received: ",
      JSON.stringify(error.request)
    );
    dispatch(recieveLoginData(true, new Error(error.request)));
  } else {
    logger.error(
      "Something happened in setting up the request that triggered an Error: ",
      JSON.stringify(error.message)
    );
    dispatch(recieveLoginData(true, new Error(error)));
  }
}

function login(email: string, password: string) {
  return (dispatch: Dispatch<LoginActions>) => {
    logger.info("Request for login: ", email);
    dispatch(requestLogin());
    return API.post(LOGIN_URL, { email: email, password: password }).then(
      response => {
        logger.info("Login response: ", JSON.stringify(response));
        if ("token" in response.data) {
          logger.info("Get user data from the server: ", email);
          let token: string = response.data.token;
          API.get(USER_URL, {
            headers: { Authorization: `Bearer ${token}` }
          }).then(
            response => {
              logger.info("Retrieved user data: ", JSON.stringify(response));
              localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token);
              window.dispatchEvent(new Event("storage"));
              dispatch(recieveLoginData(false, undefined));
            },
            error => {
              logger.error("Error after retrieving user data!");
              handleErrorsOnLogin(dispatch, error);
            }
          );
        } else if ("msg" in response.data) {
          dispatch(recieveLoginData(true, new Error(response.data.msg)));
        } else {
          dispatch(recieveLoginData(true, new Error("Unknown Error")));
        }
      },
      error => {
        logger.error("Error after login!");
        handleErrorsOnLogin(dispatch, error);
      }
    );
  };
}

function shouldLogin(state: AppState) {
  const login = state.login;
  return (
    localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY) === null &&
    (!login || !login.pending)
  );
}

export function loginIfNeeded(email: string, password: string) {
  return (
    dispatch: ThunkDispatch<AppState, null, LoginActions>,
    getState: () => AppState
  ) => {
    if (shouldLogin(getState())) {
      return dispatch(login(email, password));
    }
    return Promise.resolve();
  };
}
